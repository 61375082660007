import React, { ReactElement } from 'react'
import { ThemeProvider } from '@mui/material'
import { theme } from './utils/theme'

interface RootThemeProps {
  children: ReactElement
}

const RootThemeProvider = ({ children }: RootThemeProps): ReactElement => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default RootThemeProvider
