import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { QRCode } from 'react-qrcode-logo'
import { useTranslation } from 'react-i18next'
import { getQrInit } from '../../../utils/api'
import DarkMaibLogo from '../../../assets/svg/dark-round-logo.svg'
import MaibAndMiaLogo from '../../../assets/svg/maib-and-mia-logo.svg'
import DownloadApp from '../../../components/DownloadApp'

const QrCheckPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const id = location.pathname.replace('/qr/', '').replace('/pay/', '')
  const qrValue = window.location.origin + location.pathname
  const { t } = useTranslation()
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>()
  const [LogoComponent, setLogoComponent] = useState<string | undefined>()

  useEffect(() => {
    if (location.pathname.includes('BNM')) {
      setLogoComponent(() => MaibAndMiaLogo)
    } else {
      setLogoComponent(() => DarkMaibLogo)
    }
  }, [location.pathname])

  const { data, isError, isLoading } = useQuery(
    ['qrCheck', id],
    () => {
      return getQrInit(id ?? '')
        .then((r) => {
          if (r.ok) {
            if (location.pathname.startsWith('/pay')) {
              if (r.result.redirectUrl) {
                window.location.href = r.result.redirectUrl
                setRedirectUrl(r.result.redirectUrl)
              }
            } else if (r.result.redirectUrl) {
              window.location.href = r.result.redirectUrl
              setRedirectUrl(r.result.redirectUrl)
            } else {
              window.location.href = `maibank://qr/${id}`
            }
            return null
          }
          navigate('/error')
          return null
        })
        .catch((e) => {
          navigate('/error')
          return null
        })
    },
    {
      enabled: !!id,
    },
  )

  if (isLoading) {
    return (
      <div className='flex-1 flex items-center justify-center'>
        <CircularProgress color='secondary' size={120} thickness={4} />
      </div>
    )
  }

  if (!id) {
    navigate('/error')
  }

  return (
    <div className='flex-1 flex items-center justify-center flex-col max-w-none w-auto lg:max-w-[920px] mx-2 lg:mx-auto lg:w-full my-16 bg-grayBackground rounded-3xl pt-12 pb-6 pl-6 pr-6 box-border'>
      <div className='max-w-screen-lg flex items-center justify-center flex-col'>
        <div className='px-8 py-8 rounded-3xl bg-white max-w-fit'>
          <QRCode logoImage={LogoComponent} value={qrValue} size={244} logoWidth={56} />
        </div>
        <div className='max-w-lg text-center mb-12'>
          <h1 className='text-3xl leading-10 mt-8'>{t('qr.title')}</h1>
          <p className='text-xl leading-7 my-4'>{t('qr.description')}</p>
          {redirectUrl && (
            <a
              className='bg-main hover:bg-main box-border h-16 text-sm flex items-center justify-center text-white px-12 py-5 mt-4 rounded-full'
              href={`maibank://qr/${id}`}
            >
              {t('qr.button')}
            </a>
          )}
        </div>
      </div>
      <div className='d-flex md:mx-0 mx-4 w-full'>
        <DownloadApp />
      </div>
    </div>
  )
}

export default QrCheckPage
