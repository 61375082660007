/* eslint-disable */
import React from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { Markup } from 'interweave'
import { useTranslation } from 'react-i18next'

interface CustomInputTextFieldProps {
  label: string
  rightIcon?: React.ReactNode
  dropdown?: boolean
  error?: string | boolean | undefined
}

type InputTextFieldProps = Omit<TextFieldProps, 'error'> & CustomInputTextFieldProps
const InputTextField: React.FC<InputTextFieldProps> = ({
  rightIcon,
  error,
  autoComplete,
  dropdown = false,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <div className={'w-full flex flex-col'}>
      <div className='w-full flex flex-col gap-2 relative rounded-[12px]'>
        <TextField
          className='w-full'
          variant='outlined'
          autoComplete={autoComplete}
          sx={{
            backgroundColor: 'white',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: error ? 'red' : '#40C1AC',
              },
              '&.Mui-focused fieldset': {
                borderColor: error ? 'red' : '#40C1AC',
              },
            },
          }}
          {...props}
        />
        {rightIcon && (
          <div
            style={{
              right: dropdown ? '0' : 8,
            }}
            className='absolute top-0 h-full flex items-center'
          >
            {rightIcon}
          </div>
        )}
      </div>
      {error && (
        <div className='text-red-500 text-xs'>
          <Markup content={t(`${error}`)} />
        </div>
      )}
    </div>
  )
}

InputTextField.defaultProps = {
  rightIcon: null,
  dropdown: false,
  error: undefined,
}

export default InputTextField
