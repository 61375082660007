import { createTheme } from '@mui/material/styles'

// eslint-disable-next-line no-shadow
export enum Colors {
  '044855' = '#044855',
  White = '#FFFFFF',
  Error = '#C0404D',
  main = '#253746',
  secondary = '#40C1AC',
  yellow = '#FFB81C',
  red = '#EE2737',
  gray = '#838383',
  background = '#F5F7F9',
}

export const theme = createTheme({
  typography: {
    fontFamily: ['Nexa Regular', 'Nexa Light', 'Nexa Bold'].join(','),
  },
  palette: {
    primary: {
      main: Colors.main,
    },
    secondary: {
      main: Colors.secondary,
    },
    error: {
      main: Colors.Error,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: 64,
          fontFamily: 'Nexa Regular !important',
          fontSize: 16,
          legend: {
            color: Colors.main,
            visibility: 'hidden',
            maxWidth: 0,
          },
        },
        input: {
          padding: '20px 16px',
          paddingBottom: '12px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '10px 0',
          fontFamily: 'Nexa Regular !important',
          fontSize: 16,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          height: window.innerWidth > 620 ? 64 : 48,
          alignItems: 'center',
          WebkitAlignItems: 'center',
          justifyContent: 'center',
          WebkitJustifyContent: 'center',
          borderRadius: '30px',
          cursor: 'pointer',
          backgroundColor: Colors.secondary,
          border: 0,
          outline: 0,
          color: Colors.White,
          fontFamily: 'Nexa Regular',
          fontSize: '16px',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: Colors['044855'],
          },
          '&.Mui-disabled': {
            backgroundColor: '#40C1AC',
            opacity: 0.5,
          },
        },
        text: {
          fontSize: '16px',
          fontFamily: 'Nexa Bold',
          textTransform: 'initial',
          color: Colors.White,
          '& > .MuiPickersToolbarText-toolbarTxt': {
            width: '100%',
            color: Colors.White,
            '&.MuiTypography-h4': {
              fontSize: '24px',
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Nexa Regular !important',
          transform: 'translate(14px, 22px) scale(1)',
          '&.Mui-focused': {
            overflow: 'visible !important',
            transform: 'translate(14px, 10px) scale(0.75)',
          },
          '&.MuiFormLabel-filled': {
            transform: 'translate(14px, 10px) scale(0.75)',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          color: Colors.main,
          fontFamily: 'Nexa Regular',
        },
        h3: {
          fontSize: '36px',
          fontFamily: 'Nexa Bold',
          '@media (max-width: 620px)': {
            fontSize: '24px',
          },
        },
        h4: {
          fontSize: '24px',
          fontFamily: 'Nexa Bold',
          '@media (max-width: 620px)': {
            fontSize: '16px',
          },
        },
        h6: {
          fontSize: '13px',
          fontWeight: 800,
        },
      },
    },
  },
})
