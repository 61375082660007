import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Markup } from 'interweave'
import { useQuery } from '@tanstack/react-query'
import { Cards, ICard } from './components/Cards'
import { getCurrencies } from '../../utils/api'
import MaibRedirect from '../../components/MaibRedirect'
import MainCarousel from './components/MainCarousel'
import Currencies from './components/Currencies'

const cards: ICard[] = [
  {
    title: 'main-page.cards.card1.title',
    description: 'main-page.cards.card1.description',
    button: 'main-page.cards.card1.button',
    link: '/p2p',
  },
  {
    title: 'main-page.cards.card2.title',
    description: 'main-page.cards.card2.description',
    button: 'main-page.cards.card2.button',
    link: '/t2c',
  },
  {
    title: 'main-page.cards.card3.title',
    description: 'main-page.cards.card3.description',
    button: 'main-page.cards.card3.button',
    link: '/p2p?alias=true',
  },
]
const how = [
  {
    description: 'main-page.t2c.description1',
  },
  {
    description: 'main-page.t2c.description2',
  },
  {
    description: 'main-page.t2c.description3',
  },
  {
    description: 'main-page.t2c.description4',
  },
]

interface IHow {
  description: string
  index: number
  t: (key: string) => string
}

const HowLine: FC<IHow> = ({ description, index, t }) => {
  return (
    <div className='flex flex-col w-full items-start py-2'>
      <div className='pr-4 text-[36px] text-secondary'>0{index + 1}</div>
      <div className='flex items-start justify-start'>
        <div>{t(`${description}`)}</div>
      </div>
    </div>
  )
}
const MainPage: FC = () => {
  const { t } = useTranslation()

  const {
    data: curr,
    isError,
    isLoading,
  } = useQuery(['currencies'], () => getCurrencies(), {
    refetchOnMount: true,
  })
  return (
    <div className='h-full flex flex-1 w-full flex-col'>
      <MainCarousel />
      <Cards items={cards as ICard[]} />
      <div className='flex flex-col items-center justify-center my-12'>
        <div className='container mx-auto flex md:flex-row flex-col gap-8 px-4 items-stretch justify-between'>
          <Currencies curr={curr?.result || []} isLoading={isLoading} isError={isError} />
          <div className='md:w-6/12 w-full flex-1 flex flex-col'>
            <div className='h-full flex flex-col items-start justify-between bg-[#D9F3EE] p-[36px]'>
              <Markup
                className='text-2xl font-bold text-black '
                content={t('main-page.t2c.title')}
              />
              {how.map((item, index) => (
                <HowLine
                  key={`${index + 1}-howCard-${item?.description}`}
                  index={index}
                  description={item.description}
                  t={t}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='container mx-auto flex md:flex-row flex-col pt-12 px-4 items-stretch justify-between'>
          <MaibRedirect />
        </div>
      </div>
    </div>
  )
}
export default MainPage
