import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { CircularProgress } from '@mui/material'
import ArrowGreen from '../../../assets/svg/arrow-green.svg'
import ArrowRed from '../../../assets/svg/arrow-red.svg'
import Error from '../../../assets/svg/error.svg'

interface CurrenciesProps {
  curr: any[]
  isLoading: boolean
  isError: boolean
}

interface ITableLine {
  number: string | null
  direction: number | null
  currency: string | null
  icon?: any
}

const TableLine: FC<ITableLine> = ({ number, direction, currency, icon }) => {
  return (
    <div className='relative flex items-center w-3/12 pb-5 '>
      <div className='w-[8px] sm:w-[12px] sm:h-[8px] h-[6px] mr-1'>
        {number ? icon(direction || 0) : ' '}
      </div>
      <div className='font-nexaBold flex max-sm:text-[12px] items-center relative'>
        {number ? parseFloat(number || '0').toFixed(4) : '-'}
      </div>
    </div>
  )
}

const Currencies: React.FC<CurrenciesProps> = ({ curr = [], isLoading, isError }) => {
  const { t } = useTranslation()
  if (isLoading) {
    return (
      <div className='flex flex-col md:w-[38%] w-full bg-infoBackground flex-1 py-8 px-6'>
        {isLoading && (
          <div className='flex justify-center items-center h-full'>
            <CircularProgress color='primary' />
          </div>
        )}
      </div>
    )
  }
  if (isError) {
    return (
      <div className='flex flex-col md:w-[38%] w-full bg-infoBackground flex-1 py-8 px-6'>
        {isError && (
          <div className='flex justify-center items-center h-full'>
            <div className='text-center text-blackText text-[18px]'>
              <img src={Error} alt='error' />
            </div>
          </div>
        )}
      </div>
    )
  }
  if (!curr || curr.length === 0) {
    return (
      <div className='flex flex-col md:w-[38%] w-full bg-infoBackground flex-1 py-8 px-6'>
        {isError && (
          <div className='flex justify-center items-center h-full'>
            <div className='text-center text-blackText text-[18px]'>
              <img src={Error} alt='error' />
            </div>
          </div>
        )}
      </div>
    )
  }
  const init = curr.map((item: any) => {
    if (item.type === 'BNM') {
      return {
        ...item,
        typeSecond: item.type,
        buySecond: item.buy,
        buyDirectionSecond: item.buyDirection,
        sellSecond: item.sell,
        sellDirectionSecond: item.sellDirection,
      }
    }
    return item
  })

  const currenciesInitial = _.reduce(
    init,
    (acc: any, item: any) => {
      const index = _.findIndex(acc, (o: any) => o.currency === item.currency)
      if (index === -1) {
        acc.push(item)
      } else {
        acc[index] = {
          ...acc[index],
          ...item,
        }
      }
      return acc
    },
    [],
  )

  const currenciesInOrder = ['USD', 'EUR', 'RUB', 'RON', 'UAH']

  const reorganizedCurrencyData = currenciesInOrder.map(
    (currency) =>
      currenciesInitial.find((data: any) => {
        if (data.currency !== 'RUB') {
          return data.currency === currency
        }
        return false
      }) || {
        currency,
        buySecond: 0.0,
        sellSecond: 0.0,
        typeSecond: 'BNM',
      },
  )

  const icon = (direction: number) => {
    if (direction === 1) {
      return <img src={ArrowGreen} alt='green' />
    }
    if (direction === -1) {
      return <img src={ArrowRed} alt='red' />
    }
    return <div />
  }

  return (
    <div className='flex flex-col md:w-6/12 w-full bg-infoBackground py-8 px-6'>
      {isLoading && (
        <div className='flex justify-center items-center h-full'>
          <CircularProgress color='primary' />
        </div>
      )}
      <h3 className='text-4xl font-bold text-black'>{t('main-page.currencies.title')}</h3>
      <h5 className='text-sm font-light text-black mt-4 mb-6'>
        {t('main-page.currencies.subtitle')}
      </h5>
      <div className='flex flex-col justify-center h-full'>
        <div className='flex flex-row justify-between items-center'>
          <div className='w-3/12 flex-1' />
          <div className='text-[14px] w-3/12 font-light pb-4 pl-4 flex-1'>
            {t('main-page.currencies.buy')}
          </div>
          <div className='text-[14px] w-3/12 font-light pb-4 pl-4 flex-1'>
            {t('main-page.currencies.sell')}
          </div>
          <div className='text-[14px] w-3/12 font-light pb-4 pl-4 flex-1'>
            {t('main-page.currencies.BNM')}
          </div>
        </div>
        {reorganizedCurrencyData.map((item: any, index: number) => (
          <div key={`${index + 1}:card:`} className='flex flex-row'>
            <div className='flex items-center h-full text-center pb-5 w-3/12 flex-1 justify-center'>
              {item?.currency}
            </div>
            <TableLine
              number={item?.buy}
              direction={item?.buyDirection}
              currency={item?.currency}
              icon={icon}
            />
            <TableLine
              number={item?.sell}
              direction={item?.sellDirection}
              currency={item?.currency}
              icon={icon}
            />
            <TableLine
              number={item?.buySecond}
              direction={item?.buyDirectionSecond}
              currency={item?.currency}
              icon={icon}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Currencies
