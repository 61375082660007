/* eslint-disable */
import React, { Fragment } from 'react'
import { Disclosure, Listbox, Transition } from '@headlessui/react'
import i18next from 'i18next'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Markup } from 'interweave'
import Maib from '../assets/svg/maib.svg'

function Header() {
  const navigate = useNavigate()
  const location = window.location.pathname
  const { t } = useTranslation()

  const changeLanguage = async (language: string) => {
    await i18next.changeLanguage(language)
  }

  const navigationScreens = [
    { name: 'header.p2p', mobile: 'header.p2p-mobile', href: 'p2p', current: location === '/p2p' },
    // {
    //   name: 'header.visaAlias',
    //   mobile: 'header.visaAlias-mobile',
    //   href: 'visaAlias',
    //   current: location === '/visaAlias',
    // },
    { name: 'header.t2c', mobile: 'header.t2c-mobile', href: 't2c', current: location === '/t2c' },
    // {
    //   name: 'header.utilities',
    //   mobile: 'header.utilities-mobile',
    //   href: 'utilities',
    //   current: location === '/utilities',
    // },
  ]

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  function OldHeader() {
    return (
      <Disclosure as='nav' className='bg-main'>
        {({ open }) => (
          <>
            <div className='mx-auto container px-2 sm:px-6 lg:px-8'>
              <div className='relative flex min-h-[64px] items-center justify-between'>
                <div className='absolute inset-y-0 right-0 flex items-center sm:hidden'>
                  <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400'>
                    {open ? (
                      <svg
                        className='block h-6 w-6'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        aria-hidden='true'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M6 18L18 6M6 6l12 12'
                        />
                      </svg>
                    ) : (
                      <svg
                        className='block h-6 w-6'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        aria-hidden='true'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M4 6h16M4 12h16M4 18h16'
                        />
                      </svg>
                    )}
                  </Disclosure.Button>
                </div>
                <div className='flex flex-1 items-center  sm:items-stretch justify-start sm:pl-0 pl-4'>
                  <div
                    className='flex flex-shrink-0 items-center cursor-pointer'
                    onClick={() => {
                      navigate('/', { replace: true })
                    }}
                  >
                    <img className='block h-8 w-auto lg:hidden' src={Maib} alt='Your Company' />
                    <img className='hidden h-8 w-auto lg:block' src={Maib} alt='Your Company' />
                  </div>
                  <div className='hidden sm:block sm:ml-8'>
                    <div className='flex space-x-4='>
                      {navigationScreens.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          onClick={() => {
                            navigate(`/${item.href}`, { replace: true })
                          }}
                          style={{
                            whiteSpace: 'pre-line',
                          }}
                          className={classNames(
                            item.current ? 'text-secondary' : 'text-white  hover:text-secondary',
                            'lg:px-10 md:px-6 sm:px-4 py-2 rounded-md text-sm font-medium font-nexaRegular text-center',
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <Markup content={t(item.name)} />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>

                <div className='absolute inset-y-0 right-0  flex items-center right-[50px] sm:right-0 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                  <Listbox as='div' className='relative ml-3'>
                    <div>
                      <Listbox.Button className='flex text-md focus:text-white font-nexaBold '>
                        <span className='text-white focus:white'>{i18next.language}</span>
                      </Listbox.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Listbox.Options className='absolute right-0 z-10 mt-2 sm:w-48 w-12 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        <Listbox.Option value='ro'>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                localStorage.setItem('i18nextLng', 'ro')
                                changeLanguage('ro').then((r) => console.log(r))
                                // client.defaults.headers.common["langCode"] = 1;
                              }}
                              className={`${
                                active ? 'bg-gray-100 text-secondary' : ''
                              } block cursor-pointer px-4 py-2 text-sm text-gray-700`}
                            >
                              ro
                            </a>
                          )}
                        </Listbox.Option>
                        <Listbox.Option value='ru'>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                localStorage.setItem('i18nextLng', 'ru')
                                changeLanguage('ru').then((r) => console.log(r))
                                // client.defaults.headers.common["langCode"] = 3;
                              }}
                              className={`${
                                active ? 'bg-gray-100 text-secondary' : ''
                              } block cursor-pointer px-4 py-2 text-sm text-gray-700`}
                            >
                              ru
                            </a>
                          )}
                        </Listbox.Option>
                        <Listbox.Option value='en'>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                localStorage.setItem('i18nextLng', 'en')
                                changeLanguage('en').then((r) => console.log(r))
                                // client.defaults.headers.common["langCode"] = 2;
                              }}
                              className={`${
                                active ? 'bg-gray-100 text-secondary ' : ''
                              } block cursor-pointer px-4 py-2 text-sm text-gray-700`}
                            >
                              en
                            </a>
                          )}
                        </Listbox.Option>
                      </Listbox.Options>
                    </Transition>
                  </Listbox>
                </div>
              </div>
            </div>
            <Disclosure.Panel className='sm:hidden'>
              <div className='px-2 pt-2 pb-3 space-y-1'>
                {navigationScreens.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as='a'
                    href={item.href}
                    onClick={() => {
                      navigate(`/${item.href}`, { replace: true })
                    }}
                    className={classNames(
                      item.current ? ' text-secondary' : 'text-white  hover:text-secondary',
                      'block px-3 py-2 rounded-md text-base font-medium font-nexaRegular',
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {t(item.mobile)}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    )
  }

  return <OldHeader />
}

export default Header
