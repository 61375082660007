import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import RoundLogo from '../assets/svg/round-logo.svg'
import QrCode from '../assets/svg/qr-code.svg'
import Arrow from '../assets/svg/arrow-black.svg'

const MaibRedirect = () => {
  const { t } = useTranslation()
  return (
    <div className='rounded-[12px] flex md:flex-row flex-col justify-between w-full p-4 md:px-8 mb-8 bg-lightCyan'>
      <div className='flex md:flex-row flex-col  items-center justify-start md:w-1/2 w-full'>
        <img src={RoundLogo} alt='Round Logo' className='max-md:w-[10%]' />
        <div className='flex flex-col items-start justify-center md:ml-4 max-md:my-4 max-md:text-center'>
          <div className='text-blackText font-bold text-[20px]'>{t('success-qr.title')}</div>
          <div className='text-blackText text-[16px] max-md:py-4 max-md:text-center'>
            {t('success-qr.subtitle')}
          </div>
        </div>
      </div>
      <div className='flex flex-row items-center justify-between max-md:hidden'>
        <div className='flex flex-row items-center bg-white px-4 py-2 rounded-[12px] max-lg:hidden'>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '24px',
            }}
          >
            {t('success-qr.action')}
          </Typography>
          <img src={Arrow} alt='Arrow' className='ml-2' />
        </div>
        <img src={QrCode} alt='Qr Code' className='p-2 rounded-[12px] ml-4 bg-white w-[120px]' />
      </div>
      <div className='flex flex-row items-center justify-between md:hidden w-full '>
        {/* <img src={GooglePlay} alt="Google Play" className={'w-[48%] max-w-[200px]'} onClick={() => { */}
        {/*    ReactGA.event({ */}
        {/*            category: 'Android Download', */}
        {/*            action: `Android Download`, */}
        {/*            label: `Android Download` */}
        {/*        } */}
        {/*    ) */}
        {/*    window.open('https://play.google.com/store/apps/details?id=md.maib.maibank'); */}
        {/* }}/> */}
        {/* <img src={AppStore} alt="App Store" className={'w-[48%] max-w-[200px]'} onClick={() => { */}
        {/*    ReactGA.event({ */}
        {/*            category: 'IOS Download', */}
        {/*            action: `IOS Download`, */}
        {/*            label: `IOS Download` */}
        {/*        } */}
        {/*    ) */}
        {/*    window.open('https://apps.apple.com/us/app/maibank/id1312754339'); */}
        {/* }}/> */}
        <div className='flex flex-row gap-2 justify-between items-center w-full'>
          <a
            className='bg-blueGray flex flex-col items-center justify-center p-2 w-[30%] rounded-[12px] h-[70px] gap-1 flex-1 '
            target='_blank'
            rel='noreferrer'
            href='https://apps.apple.com/us/app/maibank/id1312754339'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 496.255 608.728'
            >
              <path
                d='M273.81 52.973C313.806.257 369.41 0 369.41 0s8.271 49.562-31.463 97.306c-42.426 50.98-90.649 42.638-90.649 42.638s-9.055-40.094 26.512-86.971zM252.385 174.662c20.576 0 58.764-28.284 108.471-28.284 85.562 0 119.222 60.883 119.222 60.883s-65.833 33.659-65.833 115.331c0 92.133 82.01 123.885 82.01 123.885s-57.328 161.357-134.762 161.357c-35.565 0-63.215-23.967-100.688-23.967-38.188 0-76.084 24.861-100.766 24.861C89.33 608.73 0 455.666 0 332.628c0-121.052 75.612-184.554 146.533-184.554 46.105 0 81.883 26.588 105.852 26.588z'
                fill='#fff'
              />
            </svg>
            <span className='text-[10px] text-clearWhite'>App store</span>
          </a>
          <a
            className='bg-blueGray flex flex-col items-center justify-center gap-1 p-2 w-[30%] rounded-[12px] h-[70px] flex-1 '
            target='_blank'
            rel='noreferrer'
            href='https://play.google.com/store/apps/details?id=md.maib.maibank'
          >
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
              <g clipPath='url(#a)'>
                <path
                  d='M19.08 9.43 2.74.23C2.46.09 2.14 0 1.84 0h-.02c-.1 0-.2.01-.29.03H1.5A1.8 1.8 0 0 0 0 1.8v18.4a1.8 1.8 0 0 0 1.5 1.77h.03l.28.03h.02c.32 0 .63-.08.91-.24l16.34-9.2a1.77 1.77 0 0 0 .67-2.47 1.8 1.8 0 0 0-.67-.66zm-6.46-1.82-1.63 2.1-4.13-5.34 5.76 3.24zm5.66 3.6L6.86 17.62l7.15-9.24 4.27 2.4c.08.05.12.13.12.21 0 .08-.04.16-.12.2v.02z'
                  fill='#fff'
                />
              </g>
              <defs>
                <clipPath id='a'>
                  <path fill='#fff' d='M0 0h20v22H0z' />
                </clipPath>
              </defs>
            </svg>
            <span className='text-[10px] text-clearWhite'>Google play</span>
          </a>
          <a
            className='bg-blueGray flex flex-col items-center justify-center gap-1 p-2 w-[30%] rounded-[12px] h-[70px] flex-1'
            target='_blank'
            rel='noreferrer'
            href='https://appgallery.huawei.com/#/app/C103002603'
          >
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
              <path
                d='M18.8.12H5.28A5.12 5.12 0 0 0 .16 5.24v13.53c0 2.82 2.3 5.11 5.12 5.11H18.8c2.83 0 5.12-2.29 5.12-5.11V5.24c0-2.83-2.29-5.12-5.12-5.12z'
                fill='#fff'
              />
              <path
                d='M5.95 1.68a.68.68 0 0 0-.67.67.67.67 0 0 0 .68.67.68.68 0 0 0 .67-.67.67.67 0 0 0-.68-.67zm12.1 0a.68.68 0 0 0-.68.67.67.67 0 0 0 .68.67.68.68 0 0 0 .67-.67.67.67 0 0 0-.68-.67z'
                fill='#515F6B'
              />
              <path
                d='M5.93 2.26c-.24.01-.23.35-.23.35a6.72 6.72 0 0 0 6.27 3.67 6.78 6.78 0 0 0 6.29-3.68s.05-.46-.44-.3a6.82 6.82 0 0 1-5.85 3.41 6.79 6.79 0 0 1-5.84-3.4.43.43 0 0 0-.2-.05zm8.54 9.55-.72 2.14-.7-2.13h-.68l1.1 3.1H14l.72-2.04.72 2.04h.54l1.1-3.1h-.67l-.7 2.13-.72-2.14h-.52zm-3.81 0-1.4 3.11h.66l.27-.6.02-.04h1.44l.29.64h.67l-1.38-3.09-.01-.02h-.56zm10.09 0v3.11h.63v-3.1h-.63zm-18.16 0v3.12h.64v-1.27h1.45v1.27h.64v-3.11h-.64v1.25H3.23v-1.25H2.6zm5.7 0v1.79c0 .5-.25.77-.71.77-.47 0-.73-.28-.73-.8v-1.75h-.64v1.78c0 .87.5 1.37 1.36 1.37.87 0 1.37-.5 1.37-1.4v-1.75h-.65zm9.3 0v3.11H20v-.56h-1.76v-.77h1.17v-.57h-1.17v-.64h1.7v-.56H17.6zm-6.66.8.46 1.03.03.07h-.97l.03-.07.45-1.04z'
                fill='#515F6B'
              />
            </svg>
            <span className='text-[10px] text-clearWhite'>App Gallery</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default memo(MaibRedirect)
