import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface ICard {
  title: string
  description: string
  button?: string
  link?: string
}

interface ICardsProps {
  title: string
  description: string
  button?: string
  link?: string
  t: (key: string) => string
}

interface ICards {
  items: ICard[]
}

const Card: FC<ICardsProps> = ({ title, description, button, link, t }) => {
  return (
    <div className='flex flex-col items-center justify-center w-full sm:w-1/3 px-4 pb-4 flex-1'>
      <div className='flex flex-col items-start justify-between w-full h-full p-8 bg-blueGray shadow-lg'>
        <div className='pb-10'>
          <h3 className='text-2xl font-bold text-white'>{t(title)}</h3>
          <p className=' mt-4 text-white'>{t(description)}</p>
        </div>
        {button && link && (
          <a
            href={link}
            className='mt-4 px-4 py-2 bg-white text-black rounded-full shadow-lg hover:bg-secondary-dark'
          >
            {t(button)}
          </a>
        )}
      </div>
    </div>
  )
}

export const Cards: React.FC<ICards> = ({ items }) => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-col items-center justify-center my-12'>
      <div className='container mx-auto flex flex-col px-4 flex-auto items-center'>
        <div className='flex sm:flex-row flex-col -mx-4'>
          {items.map((card: ICard, index: number) => (
            <Card
              key={`card-${index + 1} - ${card.title}`}
              t={t}
              title={card.title}
              description={card.description}
              button={card.button}
              link={card.link}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
