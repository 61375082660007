/* eslint-disable global-require */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import i18next from 'i18next'
import CarouselRo from '../../../assets/images/carousel-ro.png'
import CarouselRu from '../../../assets/images/carousel-ru.png'
import CarouselMobileRo from '../../../assets/images/carousel-mobile-ro.png'
import CarouselMobileRu from '../../../assets/images/carousel-mobile-ru.png'
import IntelEn from '../../../assets/images/intel-en.jpg'
import IntelRo from '../../../assets/images/intel-ro.jpg'
import IntelRu from '../../../assets/images/intel-ru.jpg'
import IntelMobileEn from '../../../assets/images/intel-mobile-en.png'
import IntelMobileRo from '../../../assets/images/intel-mobile-ro.png'
import IntelMobileRu from '../../../assets/images/intel-mobile-ru.png'
import MeToMeDesktopRo from '../../../assets/images/me-to-me-desktop-ro.jpg'
import MeToMeDesktopRu from '../../../assets/images/me-to-me-desktop-ru.jpg'
import MeToMeMobileRo from '../../../assets/images/me-to-me-mobile-ro.png'
import MeToMeMobileRu from '../../../assets/images/me-to-me-mobile-ru.png'
import MoneygramDesktopRo from '../../../assets/images/moneygram-desktop-ro.jpg'
import MoneygramDesktopRu from '../../../assets/images/moneygram-desktop-ru.jpg'
import MoneygramMobileRo from '../../../assets/images/moneygram-mobile-ro.jpg'
import MoneygramMobileRu from '../../../assets/images/moneygram-mobile-ru.jpg'
import SmithMobileRo from '../../../assets/images/smith-mobile-ro.jpg'
import SmithMobileRu from '../../../assets/images/smith-mobile-ru.jpg'
import SmithDesktopRo from '../../../assets/images/smith-desktop-ro.jpg'
import SmithDesktopRu from '../../../assets/images/smith-desktop-ru.jpg'

const MainCarousel = () => {
  const { t } = useTranslation()
  const secondImg = () => {
    if (i18next.language === 'ru') {
      return require('../../../assets/images/main-desktop-ru.png')
    }
    if (i18next.language === 'ro') {
      return require('../../../assets/images/main-desktop-ro.png')
    }
    return require('../../../assets/images/main-desktop-en.png')
  }
  const secondImgMobile = () => {
    if (i18next.language === 'ru') {
      return require('../../../assets/images/Mobile-maibpay-ru.png')
    }
    if (i18next.language === 'ro') {
      return require('../../../assets/images/Mobile-maibpay-ro.png')
    }
    return require('../../../assets/images/Mobile-maibpay-eng.png')
  }

  const getImageSrc = (isMobile: boolean) => {
    const lang = i18next.language

    if (isMobile) {
      if (lang === 'ru') {
        return IntelMobileRu
      }
      if (lang === 'ro') {
        return IntelMobileRo
      }
      return IntelMobileEn
    }
    if (lang === 'ru') {
      return IntelRu
    }
    if (lang === 'ro') {
      return IntelRo
    }
    return IntelEn
  }

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      autoPlay
      interval={5000}
      showArrows={false}
      emulateTouch
      renderIndicator={(onClickHandler, isSelected, index, label) => (
        <li
          style={{
            background: isSelected ? '#000' : '#fff',
            position: 'relative',
            top: 15,
            width: '40px',
            height: '1px',
            display: 'inline-block',
            margin: '0 8px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          value={index}
          key={index}
        />
      )}
      className='w-full h-full '
      transitionTime={1000}
    >
      <div className='h-full w-full flex justify-center items-center bg-white relative '>
        <picture>
          <source media='(min-width: 768px)' srcSet={secondImg()} />
          <source media='(max-width: 767px)' srcSet={secondImgMobile()} />
          <img
            src={secondImg()}
            alt='CarouselDesktop'
            className=' h-full '
            style={{
              objectFit: 'fill',
            }}
          />
        </picture>
      </div>
      <button
        type='button'
        className='h-full w-full flex justify-center items-center bg-white cursor-pointer relative '
        onClick={() => {
          window.location.href = '/p2p?alias=true'
        }}
      >
        <picture>
          <source
            media='(min-width: 768px)'
            srcSet={i18next.language === 'ru' ? MeToMeDesktopRu : MeToMeDesktopRo}
          />
          <source
            media='(max-width: 767px)'
            srcSet={i18next.language === 'ru' ? MeToMeMobileRu : MeToMeMobileRo}
          />
          <img
            src={i18next.language === 'ru' ? MeToMeDesktopRu : MeToMeDesktopRo}
            alt='CarouselDesktop'
            className=' h-full '
            style={{
              objectFit: 'fill',
            }}
          />
        </picture>
      </button>
      <button
        type='button'
        className='h-full w-full flex justify-center items-center bg-white cursor-pointer relative '
        onClick={() => {
          window.location.href = '/t2c'
        }}
      >
        <picture>
          <source
            media='(min-width: 768px)'
            srcSet={i18next.language === 'ru' ? SmithDesktopRu : SmithDesktopRo}
          />
          <source
            media='(max-width: 767px)'
            srcSet={i18next.language === 'ru' ? SmithMobileRu : SmithMobileRo}
          />
          <img
            src={i18next.language === 'ru' ? SmithMobileRu : SmithMobileRo}
            alt='CarouselDesktop'
            className=' h-full '
            style={{
              objectFit: 'fill',
            }}
          />
        </picture>
      </button>
      <button
        type='button'
        className='h-full w-full flex justify-center items-center bg-white cursor-pointer relative '
        onClick={() => {
          window.location.href = '/t2c'
        }}
      >
        <picture>
          <source
            media='(min-width: 768px)'
            srcSet={i18next.language === 'ru' ? MoneygramDesktopRu : MoneygramDesktopRo}
          />
          <source
            media='(max-width: 767px)'
            srcSet={i18next.language === 'ru' ? MoneygramMobileRu : MoneygramMobileRo}
          />
          <img
            src={i18next.language === 'ru' ? MoneygramMobileRu : MoneygramMobileRo}
            alt='CarouselDesktop'
            className=' h-full '
            style={{
              objectFit: 'fill',
            }}
          />
        </picture>
      </button>
      <button
        type='button'
        className='h-full w-full flex justify-center items-center bg-white cursor-pointer relative '
        onClick={() => {
          window.location.href = '/t2c'
        }}
      >
        <picture>
          <source media='(min-width: 768px)' srcSet={getImageSrc(false)} />
          <source media='(max-width: 767px)' srcSet={getImageSrc(true)} />
          <img
            src={getImageSrc(false)}
            alt='CarouselDesktop'
            className=' h-full '
            style={{
              objectFit: 'fill',
            }}
          />
        </picture>
      </button>
      <button
        type='button'
        className='h-full w-full flex justify-center items-center bg-white cursor-pointer relative '
        onClick={() => {
          window.location.href = '/p2p'
        }}
      >
        <picture>
          <source
            media='(min-width: 768px)'
            srcSet={i18next.language === 'ru' ? CarouselRu : CarouselRo}
          />
          <source
            media='(max-width: 767px)'
            srcSet={i18next.language === 'ru' ? CarouselMobileRu : CarouselMobileRo}
          />
          <img
            src={i18next.language === 'ru' ? CarouselRu : CarouselRo}
            alt='CarouselDesktop'
            className=' h-full '
            style={{
              objectFit: 'fill',
            }}
          />
        </picture>
      </button>
    </Carousel>
  )
}

export default MainCarousel
