import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import CreditCardMask from '../../../../../components/CreditCardMask'
import MaskInput from '../../../../../components/MaskInput'
import InputTextField from '../../../../../components/InputTextField'

interface ICreditCardForm {
  formik: any
}

const CreditCardForm: FC<ICreditCardForm> = ({ formik }) => {
  const { t } = useTranslation()
  return (
    <div className='w-full flex flex-col max-w-[342px] '>
      <CreditCardMask
        value={formik.values.cardNumber}
        id='cardNumber'
        name='cardNumber'
        tabIndex={0}
        handleClear={() => {
          formik.setFieldValue('cardNumber', '')
        }}
        placeholder={t('p2p-page.mainForm.receiver-title') || ''}
        onChange={(e) => formik.setFieldValue('cardNumber', e.target.value)}
        onBlur={(e) => {
          formik.handleBlur(e)
        }}
        error={formik.touched?.cardNumber && formik.errors?.cardNumber}
      />
      <div className='w-full flex sm:flex-row flex-col sm:gap-2 '>
        <InputTextField
          label={t('p2p-page.mainForm.name')}
          name='firstName'
          id='firstName'
          tabIndex={0}
          className='w-full '
          value={formik.values.firstName}
          error={formik.touched?.firstName && formik.errors.firstName}
          onChange={(e) => {
            const regex = /^[a-zA-Z ]*$/
            if (regex.test(e.target.value)) {
              formik.setFieldValue('firstName', e.target.value)
            }
          }}
          onBlur={(e) => formik.handleBlur(e)}
        />
        <InputTextField
          id='lastName'
          name='lastName'
          tabIndex={0}
          label={t('p2p-page.mainForm.surname')}
          className='w-full '
          value={formik.values.lastName}
          onChange={(e) => {
            const regex = /^[a-zA-Z ]*$/
            if (regex.test(e.target.value)) {
              formik.setFieldValue('lastName', e.target.value)
            }
          }}
          onBlur={(e) => formik.handleBlur(e)}
          error={formik.touched?.lastName && formik.errors?.lastName}
        />
      </div>
      <div className='flex sm:flex-row flex-col justify-between w-full sm:gap-2'>
        <MaskInput
          type='tel'
          tabIndex={0}
          id='expirationDate'
          name='expirationDate'
          placeholder={t('p2p-page.mainForm.expiry-title')}
          mask='99/99'
          value={formik.values.expirationDate ? formik.values.expirationDate : ''}
          className='sm:w-1/2 w-full'
          onChange={(e) => formik.setFieldValue('expirationDate', e.target.value)}
          onBlur={(e) => {
            formik.handleBlur(e)
          }}
          errorString={formik.touched?.expirationDate && formik.errors.expirationDate}
        />
        <MaskInput
          value={formik.values.cvv}
          mask='999'
          tabIndex={0}
          id='cvv'
          placeholder='CVV'
          inputProps={{
            type: 'password',
          }}
          onChange={formik.handleChange}
          className='sm:w-1/2 w-full'
          onBlur={formik.handleBlur}
          errorString={formik.touched?.cvv && formik.errors.cvv}
        />
      </div>
    </div>
  )
}

export default CreditCardForm
