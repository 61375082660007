import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { StyledEngineProvider } from '@mui/styled-engine-sc'
import './i18n/config'
import ReactGA from 'react-ga4'
import App from './App'
import RootThemeProvider from './RootThemeProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  },
})

const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || ''

ReactGA.initialize(GOOGLE_ANALYTICS_ID)

ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname + window.location.search,
})

root.render(
  <RootThemeProvider>
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </StyledEngineProvider>
  </RootThemeProvider>,
)
