/* eslint-disable no-shadow */
interface ICurrency {
  id: number
  ccy: number
  charCode: string
  currencyName: string
}

export enum CurrencyEnum {
  mdl = 'mdl',
  eur = 'eur',
  usd = 'usd',
}

export const CurrencyType: Record<CurrencyEnum, ICurrency> = {
  [CurrencyEnum.mdl]: {
    id: 0,
    ccy: 498,
    charCode: 'MDL',
    currencyName: 'Moldavian leu',
  },
  [CurrencyEnum.eur]: {
    id: 0,
    ccy: 978,
    charCode: 'EUR',
    currencyName: 'Euro',
  },
  [CurrencyEnum.usd]: {
    id: 0,
    ccy: 840,
    charCode: 'USD',
    currencyName: 'USA Dollar',
  },
}

export enum UtilityCategoriesEnum {
  Telephony = 'Telephony',
  InternetTV = 'InternetTV',
  Utilities = 'Utilities',
  'Bank&Finance' = 'Bank&Finance',
  Ecommerce = 'Ecommerce',
}

export type UtilityItem = {
  name: string // name can be any string
  // ... other properties of the item
}

export const UtilityCategoriesText: Record<UtilityCategoriesEnum, { text: string }> = {
  [UtilityCategoriesEnum.Telephony]: {
    text: 'utilities.categories.telephone',
  },
  [UtilityCategoriesEnum.InternetTV]: {
    text: 'utilities.categories.internet',
  },
  [UtilityCategoriesEnum.Utilities]: {
    text: 'utilities.categories.utilities',
  },
  [UtilityCategoriesEnum['Bank&Finance']]: {
    text: 'utilities.categories.bank',
  },
  [UtilityCategoriesEnum.Ecommerce]: {
    text: 'utilities.categories.ecommerce',
  },
}

export function getCharCode(ccy: number): string | undefined {
  const currencyType = Object.keys(CurrencyType).find(
    (key: any) => CurrencyType[key as CurrencyEnum].ccy === ccy,
  )
  return currencyType ? CurrencyType[currencyType as CurrencyEnum].charCode : undefined
}

export enum AliasSteps {
  OTP_INIT = 0,
  OTP_CONFIRM = 1,
  MAIN_FORM = 2,
}

export enum UtilitiesSteps {
  TRANSACTION_INTRO,
  TRANSACTION_INIT,
  TRANSACTION_COMPLETE,
  TRANSACTION_CONFIRM,
  TRANSACTION_SUCCESS,
  TRANSACTION_ERROR,
}

export enum PaymentSteps {
  PAYMENT_INTRO,
  PAYMENT_INIT,
  PAYMENT_COMPLETE,
  PAYMENT_CONFIRM,
}

export const InputProps = (provider: number) => {
  switch (provider) {
    case 1:
      return {
        maxLength: 16,
        minLength: 4,
        pattern: '[0-9]*',
      }
    case 3:
      return {
        maxLength: 8,
        minLength: 8,
        pattern: '[0-9]*',
      }
    case 5:
      return {
        maxLength: 17,
        minLength: 11,
        pattern: '[0-9]*',
      }
    case 6:
      return {
        maxLength: 11,
        minLength: 11,
      }
    case 7:
      return {
        maxLength: 10,
        minLength: 10,
      }
    case 10:
      return {
        minLength: 1,
        maxLength: 16,
      }
    case 11:
      return {
        minLength: 1,
        maxLength: 16,
      }
    case 1009:
      return {
        minLength: 1,
        maxLength: 16,
      }
    case 1010:
      return {
        minLength: 5,
        maxLength: 16,
      }
    default:
      return {
        minLength: 1,
        maxLength: 16,
      }
  }
}
