import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import GooglePlay from '../assets/svg/GooglePlay.svg'
import AppStore from '../assets/svg/AppStore.svg'
import Appgalery from '../assets/svg/AppGallery.svg'

const DownloadApp = () => {
  const { t } = useTranslation()
  return (
    <div className='rounded-[12px] w-full p-6 mb-8 bg-infoBackground'>
      <div className='flex flex-col  items-center justify-center w-full'>
        <div className='flex flex-col items-center justify-center md:ml-4 md:my-4 max-md:text-center'>
          <div className='text-blackText font-bold text-[20px]'>{t('success-qr.title')}</div>
          <div className='text-blackText text-[14px] max-md:py-4 max-md:text-center'>
            {t('success-qr.subtitle')}
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center justify-between w-full md:mt-4 '>
        <div className='flex flex-col sm:flex-row gap-2 justify-between items-center w-full'>
          <a
            className='bg-main flex flex-row items-center justify-center p-2 w-full max-w-[160px] sm:max-w-none sm:w-[30%] rounded-[12px] h-[40px]'
            target='_blank'
            rel='noreferrer'
            href='https://apps.apple.com/us/app/maibank/id1312754339'
          >
            <img src={AppStore} alt='AppStore' className='mr-2 h-6' />
            <span className='text-4 text-clearWhite'>App store</span>
          </a>
          <a
            className='bg-main flex flex-row items-center justify-center gap-1 p-2 w-full max-w-[160px] sm:max-w-none sm:w-[30%] rounded-[12px] h-[40px]'
            target='_blank'
            rel='noreferrer'
            href='https://play.google.com/store/apps/details?id=md.maib.maibank'
          >
            <img src={GooglePlay} alt='GooglePlay' className='mr-2 h-6' />
            <span className='text-4 text-clearWhite'>Google play</span>
          </a>
          <a
            className='bg-main flex flex-row items-center justify-center gap-1 p-2 w-full max-w-[160px] sm:max-w-none sm:w-[30%] rounded-[12px] h-[40px]'
            target='_blank'
            rel='noreferrer'
            href='https://appgallery.huawei.com/#/app/C103002603'
          >
            <img src={Appgalery} alt='Appgalery' className='mr-2 h-6' />
            <span className='text-4 text-clearWhite'>App Gallery</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default memo(DownloadApp)
