import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@mui/material'
import Search from '../../../../assets/svg/search.svg'
import CategoryList from './components/CategoryList'
import { IUtilitiesCategory, IUtilitiesProduct } from '../types'
import CategoryForm from './components/CategoryForm'
import { UtilitiesSteps } from '../../../../utils/enum'
import CategoryDropdown from './components/CategoryDropdown'

interface IMainForm {
  handleCategoryPress: (id: string) => void
  activeCategory: string
  setStep: (step: UtilitiesSteps) => void
  categoriesList?: IUtilitiesCategory[]
  providers: any
  handleLoadMore: () => void
  activeProduct?: IUtilitiesProduct | null
  setActiveProduct: (product: IUtilitiesProduct | null) => void
  setSearch: (search: string) => void
  search: string
  handleInitTransaction: (identifier: string, formik: any) => void
  hasNextPage?: boolean
  providersLoading: boolean
  isFetching: boolean
}

const MainForm: FC<IMainForm> = ({
  handleCategoryPress,
  activeCategory,
  setStep,
  categoriesList,
  providers,
  handleLoadMore,
  hasNextPage,
  setSearch,
  search,
  activeProduct,
  setActiveProduct,
  handleInitTransaction,
  providersLoading,
  isFetching,
}) => {
  const { t } = useTranslation()

  return (
    <div className='container w-full h-full py-12 px-4'>
      <Typography variant='h3' className='text-left'>
        {t('utilities.mainForm.title')}
      </Typography>
      <div className='flex sm:flex-row flex-col justify-between gap-4 pt-5'>
        <div className=' flex-col sm:w-3/12 w-full items-center'>
          <div className='relative w-full pl-4 pr-9 bg-white rounded-[12px] sm:mb-1 mb-2'>
            <input
              type='text'
              className='sm:h-[44px] h-[64px] w-full rounded-[12px] outline-none'
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder={t('utilities.mainForm.search') || search}
            />
            <div className='absolute top-0 bottom-0 right-2 flex items-center '>
              <img src={Search} alt='' />
            </div>
          </div>
          <CategoryList
            handleCategory={handleCategoryPress}
            categories={categoriesList}
            category={activeCategory}
          />
          <CategoryDropdown
            handleCategory={handleCategoryPress}
            categories={categoriesList}
            category={activeCategory}
          />
        </div>
        <div className=' flex-col sm:w-9/12 w-full justify-start'>
          {isFetching ? (
            <div className='w-full flex items-center justify-center bg-white rounded-[12px] py-[20vh]'>
              <CircularProgress size={40} thickness={4} color='secondary' />
            </div>
          ) : (
            <CategoryForm
              isLoading={false}
              activeImage={
                categoriesList && categoriesList.find((c) => c.id === activeCategory)?.image
              }
              setStep={setStep}
              products={providers || []}
              setActiveProduct={setActiveProduct}
              hasNextPage={hasNextPage}
              handleLoadMore={handleLoadMore}
              activeProduct={activeProduct}
              providersLoading={providersLoading}
              handleInitTransaction={handleInitTransaction}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default MainForm
