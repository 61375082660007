import React, { type FC } from 'react'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Success from '../../assets/svg/success.svg'

const InfoLine = ({ title, value }: { title: string; value: string | number }) => {
  return (
    <div className='flex flex-row items-center py-1 justify-between w-full'>
      <Typography variant='body2' className='text-left text-secondary' color='#696F8C'>
        {title}
      </Typography>
      <Typography variant='body2' className='text-right text-secondary'>
        {value}
      </Typography>
    </div>
  )
}

const UtilitiesSuccessPage: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const result = location.state?.result
  const { t } = useTranslation()

  return (
    <div className='flex-1 flex items-center justify-center bg-white'>
      <div className='w-full flex flex-col items-center justify-center bg-mainBackground container h-full sm:my-12 my-4 mx-4  p-4 px-6  h-full rounded-[12px]'>
        <img src={Success} alt='' />
        <Typography variant='h3' className='text-center !my-4 text-secondary' color='secondary'>
          {t('utilities.success.title')}
        </Typography>
        {result && (
          <div className='flex flex-col w-full m-4 bg-white max-w-[384px] p-4 rounded-[24px]'>
            <InfoLine title={t('utilities.success.card')} value={result.card} />
            <InfoLine title={t('utilities.success.cardBrand')} value={result.cardBrand} />
            <InfoLine title={t('utilities.success.fullName')} value={result.fullName} />
            <InfoLine title={t('utilities.success.provider')} value={result.providerTitle} />
            <InfoLine title={t('utilities.success.rrnCode')} value={result.rrnCode} />
            <div className='w-full h-[1px] bg-gray-200 my-4' />
            <InfoLine
              title={t('utilities.success.totalAmount')}
              value={`${result?.totalAmount} ${result?.currency}`}
            />
          </div>
        )}
        <Button
          variant='contained'
          className='mt-8 max-w-[384px] !bg-transparent !text-black  w-full'
          sx={{
            border: '1px solid #696F8C70',
          }}
          onClick={() => navigate('/#top')}
        >
          {t('utilities.success.goHome')}
        </Button>
      </div>
    </div>
  )
}

export default UtilitiesSuccessPage
