import React, { type FC } from 'react'
import { MenuItem, Select } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { IUtilitiesCategory } from '../../types'
import { UtilityCategoriesEnum, UtilityCategoriesText } from '../../../../../utils/enum'

interface ICategoryDropdown {
  categories?: IUtilitiesCategory[]
  handleCategory: (id: string) => void
  category: string | null
}

const CategoryDropdown: FC<ICategoryDropdown> = ({ categories, handleCategory, category }) => {
  const { t } = useTranslation()
  return (
    <div className='w-full sm:hidden flex'>
      <Select
        value={category}
        onChange={(e) =>
          category === e.target.value ? handleCategory('') : handleCategory(e.target?.value || '')
        }
        className='w-full !bg-white !border-0 !rounded-[12px]'
        sx={{
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            padding: '0 0 0 16px',
          },
          fieldset: {
            border: 'none',
          },
        }}
      >
        {categories &&
          categories.map((item: IUtilitiesCategory, index: number) => {
            const imageTitleSplit = item.image.title.split('.')
            const imageType = imageTitleSplit[imageTitleSplit.length - 1]
            const name =
              item.name in UtilityCategoriesEnum
                ? UtilityCategoriesText[item.name as UtilityCategoriesEnum].text
                : item.name
            return (
              <MenuItem
                key={`category-${index + 1}-${item.id}`}
                value={item.id}
                className={`flex flex-row w-full items-center py-2 px-3 rounded-[12px] my-2 hover:bg-[#B3E6DE82] hover:border-secondary cursor-pointer transition-all duration-300 ease-in-out border-solid border-[1px]${
                  category === item.id ? ' bg-[#B3E6DE52] ' : ''
                }`}
              >
                <img
                  src={`data:image/${imageType === 'svg' ? 'svg+xml' : imageType};base64,${
                    item.image.base64
                  }`}
                  alt=''
                  className='h-[24px]'
                />
                <Typography
                  variant='body1'
                  className='!text-[14px] !font-nexaBold !ml-4 text-center truncate'
                >
                  {t(`${name}`)}
                </Typography>
              </MenuItem>
            )
          })}
      </Select>
    </div>
  )
}

export default CategoryDropdown
