/* eslint-disable */
import React from 'react'
import InputMask from 'react-input-mask'
import { InputProps } from '@mui/material'
import { Markup } from 'interweave'
import { useTranslation } from 'react-i18next'
import InputTextField from './InputTextField'

interface MaskInputProps extends InputProps {
  value: string
  mask: string
  id: string
  name?: string
  placeholder: string
  onChange: (e: any) => void
  onFocus?: (e: any) => void
  onBlur?: (e: any) => void
  className?: string
  maskPlaceholder?: string | null
  errorString?: string
  autoComplete?: string
  inputProps?: any
}

const MaskInput: React.FC<MaskInputProps> = ({
  value,
  mask,
  id,
  name,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  className,
  maskPlaceholder = '•',
  errorString,
  autoComplete,
  inputProps,
  ...props
}) => {
  const { t } = useTranslation()
  const Input = () => {
    return (
      <InputTextField
        variant='outlined'
        label={placeholder}
        className={errorString ? 'border-red-500' : 'border-black'}
        inputProps={inputProps}
        sx={{
          margin: '0 !important',
          backgroundColor: 'white',
          overflow: 'hidden !important',
          '& .MuiInputLabel-root': {
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            '&.Mui-focused': {
              overflow: 'hidden !important',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: errorString ? 'red' : 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover fieldset': {
              borderColor: errorString ? 'red' : '#40C1AC',
            },
            '&.Mui-focused fieldset': {
              borderColor: errorString ? 'red' : '#40C1AC',
            },
          },
        }}
      />
    )
  }
  return (
    <div className='w-full flex-1 py-[10px]'>
      <InputMask
        mask={mask}
        maskPlaceholder={maskPlaceholder}
        className={`${className} !w-full`}
        value={value}
        type='tel'
        x-autocomplete={autoComplete}
        autoComplete={autoComplete}
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {Input()}
      </InputMask>
      <div className='text-red-500 text-xs'>
        {errorString ? <Markup content={t(`${errorString}`)} /> : ' '}
      </div>
    </div>
  )
}

export default MaskInput
