import { forwardRef, ReactElement, Ref, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { styled } from '@mui/material/styles'
import './CSS/PDFViewer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement
    },
    ref: Ref<unknown>,
  ) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Slide direction='up' ref={ref} {...props} />
  ),
)

const PDFViewer = ({ name, pdf, className, drivePdf }: any) => {
  const [open, setOpen] = useState(false)
  const [numPages, setNumPages] = useState<number | null>(null)

  const handleClose = () => {
    setOpen(false)
  }

  // eslint-disable-next-line no-shadow
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages)
  }

  const PDFButton = styled('a')(() => ({
    backgroundColor: 'transparent',
    padding: 0,
    minWidth: 0,
    height: 'auto',
    fontSize: 'inherit',
    color: 'white',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }))

  const ScrollContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflowY: 'auto',
    height: '100%',
    padding: '20px',
    backgroundColor: '#fff',
  })

  const StyledPage = styled(Page)({
    marginBottom: '20px',
  })

  return (
    <>
      <PDFButton
        className={
          className ||
          'cursor-pointer underline hover:no-underline lowercase transition text-sm duration-150 ease-out hover:ease-in mr-4 xl:mr-10'
        }
        onClick={(event) => {
          event.preventDefault()
          setOpen(true)
        }}
      >
        {name}
      </PDFButton>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar className='flex-row flex justify-between w-full'>
            <div className='flex-row sm:w-1/3 w-1/2 flex justify-start'>
              <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant='h6' component='div'>
                {name}
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
        {drivePdf ? (
          <div className='flex w-full justify-center bg-[#0d0e0e]'>
            <iframe
              title='pdf'
              className='pdf-container'
              src={pdf}
              width='640'
              height='480'
              allow='autoplay'
            />
          </div>
        ) : (
          <ScrollContainer className='pdf-scroll-container'>
            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <StyledPage
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={window.innerWidth > 620 ? window.innerWidth / 2 : window.innerWidth - 20}
                />
              ))}
            </Document>
          </ScrollContainer>
        )}
      </Dialog>
    </>
  )
}

export default PDFViewer
