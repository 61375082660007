import React from 'react'
import './App.css'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import { GoogleReCaptchaProvider as GoogleReCaptchaV3Provider } from 'react-google-recaptcha-v3'
import './fonts/NexaBold.otf'
import './fonts/NexaLight.otf'
import './fonts/NexaRegular.otf'
import i18next from 'i18next'
import Header from './components/Header'
import Footer from './components/Footer'
import ViberSupport from './components/ViberSupport'
import ErrorPage from './pages/404'
import SuccessPage from './pages/succes'
import VisaAlias from './pages/visaAlias'
import { GoogleReCaptchaV2Provider } from './hooks/useReCaptchaV2'
import MainPage from './pages/mainPage'
import CheckP2PPage from './pages/p2p/CheckP2PPage'
import { PayEcommercePage, QrCheckPage } from './pages/qr'
import Utilities from './pages/utilities'
import CheckPage from './pages/utilities/CheckPage'
import UtilitiesSuccessPage from './pages/utilities/UtiltiesSuccessPage'

const P2P = React.lazy(async () =>
  import('./pages/p2p').then(({ default: P2PComponent }) => ({ default: P2PComponent })),
)
const T2C = React.lazy(async () =>
  import('./pages/t2c').then(({ default: T2CComponent }) => ({ default: T2CComponent })),
)

const App = () => {
  const V3_API = process.env.REACT_APP_V3_SITE_KEY || ''
  const V2_API = process.env.REACT_APP_V2_SITE_KEY || ''
  const [searchParams] = useSearchParams()
  const langcode = searchParams.get('lang')

  React.useEffect(() => {
    if (langcode) {
      i18next.changeLanguage(langcode)
      localStorage.setItem('i18nextLng', langcode)
    } else if (!localStorage.getItem('i18nextLng')) {
      localStorage.setItem('i18nextLng', i18next.language)
    } else {
      i18next.changeLanguage(localStorage.getItem('i18nextLng') || 'ro')
    }
  }, [langcode])

  return (
    <GoogleReCaptchaV3Provider reCaptchaKey={V3_API}>
      <GoogleReCaptchaV2Provider value={V2_API}>
        <div className='flex flex-col min-h-full mx-auto w-full bg-mainBackground'>
          <Header />
          <Routes>
            <Route path='/' element={<MainPage />} />
            <Route path='/p2p' element={<P2P />} />
            <Route path='/p2p/check' element={<CheckP2PPage />} />
            {/* <Route path='/visaAlias' element={<VisaAlias />} /> */}
            <Route path='/error' element={<ErrorPage />} />
            <Route path='/succes' element={<SuccessPage />} />
            <Route path='/t2c' element={<T2C />} />
            <Route path='/qr/*' element={<QrCheckPage />} />
            <Route path='/qr/ecommerce/:id' element={<PayEcommercePage />} />
            <Route path='/pay/*' element={<QrCheckPage />} />
            <Route path='/pay/ecommerce/:id' element={<PayEcommercePage />} />
            <Route path='/utilities-test-env' element={<Utilities />} />
            <Route path='/utilities/check' element={<CheckPage />} />
            <Route path='/utilities/success' element={<UtilitiesSuccessPage />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
          <ViberSupport />
          <Footer />
        </div>
      </GoogleReCaptchaV2Provider>
    </GoogleReCaptchaV3Provider>
  )
}

export default App
