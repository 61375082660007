import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { checkP2P } from '../../utils/api'

const CheckP2PPage = () => {
  const [searchParams] = useSearchParams()
  const operationId = searchParams.get('operationId') || ''
  const [loading, setLoading] = React.useState<boolean>(true)
  const { data, isError } = useQuery(['p2pPayStatus'], () => checkP2P(operationId), {
    refetchInterval: 5000,
  })
  const navigate = useNavigate()
  useEffect(() => {
    if (data?.ok === false) {
      navigate('/error', { state: { location: '/p2p' } })
    }
    if (data?.result?.status === 1) {
      setLoading(false)
      navigate('/succes', { state: { location: '/p2p' } })
    }
    if (data?.result?.status === 2) {
      setLoading(false)
      navigate('/error', { state: { location: '/p2p' } })
    } else {
      setLoading(true)
    }
  }, [data])

  if (isError) {
    navigate('/error', { state: { location: '/p2p' } })
  }

  if (loading) {
    return (
      <div className='flex-1 flex items-center justify-center'>
        <CircularProgress color='secondary' size={120} thickness={4} />
      </div>
    )
  }
  return (
    <div className='flex-1 flex items-center justify-center'>
      <CircularProgress color='secondary' size={120} thickness={4} />
    </div>
  )
}

export default CheckP2PPage
