import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTrans from './en/translation.json'
import roTrans from './ro/translation.json'
import ruTrans from './ru/translation.json'

export const resources = {
  en: { translation: enTrans },
  ro: { translation: roTrans },
  ru: { translation: ruTrans },
}

i18next.use(initReactI18next).init({
  lng: 'ro',
  fallbackLng: 'ro',
  // debug: true,
  resources,
})

export default i18next
