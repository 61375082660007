import { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IUtilitiesCategory } from '../../types'
import ArrowLeft from '../../../../../assets/svg/arrow-down.svg'
import { UtilityCategoriesEnum, UtilityCategoriesText } from '../../../../../utils/enum'

interface ICategoryCard {
  item: IUtilitiesCategory
  handleClick: (id: string) => void
}

const CategoryCard: FC<ICategoryCard> = ({ item, handleClick }) => {
  const { t } = useTranslation()
  const imageTitleSplit = item.image.title.split('.')
  const imageType = imageTitleSplit[imageTitleSplit.length - 1]
  const name =
    item.name in UtilityCategoriesEnum
      ? UtilityCategoriesText[item.name as UtilityCategoriesEnum].text
      : item.name
  return (
    <div className='md:w-[23%] sm:w-[48%] w-[90%] sm:h-[160px] h-[64px] '>
      <button
        type='button'
        className='w-full h-full flex sm:flex-col flex-row bg-white rounded-[16px] max-sm:px-4 items-center sm:justify-center justify-between cursor-pointer hover:bg-[#00000010] transition duration-500 ease-in-out '
        onClick={() => {
          handleClick(item.id)
        }}
      >
        <div className='sm:flex-col flex-row flex items-center sm:justify-center'>
          <img
            src={`data:image/${imageType === 'svg' ? 'svg+xml' : imageType};base64,${
              item.image.base64
            }`}
            alt=''
            className='sm:h-[40px] h-[24px]'
          />
          <div className='sm:text-center text-left font-nexaBold pt-2 max-sm:pl-2'>
            {t(`${name}`)}
          </div>
        </div>
        <div className='sm:hidden'>
          <img src={ArrowLeft} alt='arrow-left' className='sm:h-[24px] h-[16px] -rotate-90' />
        </div>
      </button>
    </div>
  )
}

export default CategoryCard
