import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { Button, Checkbox, CircularProgress } from '@mui/material'
import { Markup } from 'interweave'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { getQrEcommerceInit, postQrEcommerceConfirm } from '../../../../utils/api'
import InputTextField from '../../../../components/InputTextField'
import i18next from '../../../../i18n/config'
import PhoneInputComponent from '../../../../components/PhoneInputComponent'
import Secure from '../../../../assets/svg/3ds-secure.svg'
import VSMG from '../../../../assets/svg/vsmg.svg'

interface IResult {
  amount: number
  currency: string
  canChangeAmount: boolean
  productName: string
  description: string
  operationId: string
  companyName: string
  projectTermsUrl: string
}

const PayEcommercePage = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [errors, setErrors] = React.useState([])
  const { data, isLoading, isError } = useQuery<IResult>(
    ['payEcommerce', id],
    () => {
      return getQrEcommerceInit(id ?? '').then((r) => {
        if (r?.ok) {
          console.log(r)
          return r.result
        }
        navigate('/error')
        return null
      })
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      agree: false,
      amount: data?.amount ? data?.amount.toString() : '',
      currency: data?.currency || '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('p2p-page.error.required'),
      phone: Yup.string(),
      email: Yup.string().email('p2p-page.error.error').required('p2p-page.error.required'),
      amount: Yup.number().required('p2p-page.error.required'),
      agree: Yup.boolean().equals([true], 'p2p-page.error.required'),
    }),
    onSubmit: async (values) => {
      const valuesToSend = {
        fullName: values.name,
        phone: `+${values.phone}`,
        email: values.email,
        amount: parseFloat(values.amount),
        language: i18next.language,
      }
      await postQrEcommerceConfirm(data?.operationId ?? '', valuesToSend)
        .then((r) => {
          if (!r?.ok) {
            setErrors(r?.errors ?? [])
          }
          if (r.result?.payUrl) {
            window.open(r.result.payUrl, '_self')
          }
          return r
        })
        .catch((e) => {
          navigate('/error')
        })
    },
  })

  useEffect(() => {
    if (data && data.amount) {
      formik.setFieldValue('amount', data.amount.toString())
      formik.setFieldValue('currency', data.currency)
    }
  }, [data])

  if (isLoading) {
    return (
      <div className='flex-1 flex items-center justify-center'>
        <CircularProgress color='secondary' size={120} thickness={4} />
      </div>
    )
  }
  if (isError) {
    navigate('/error')
  }

  const isDisabled = !formik.values.agree
  return (
    <div className='flex flex-col justify-center items-center flex-1 p-8 '>
      <div className='flex flex-col items-center sm:mb-[80px] mb-4'>
        <Markup className='text-[36px] text-center font-bold' content={t('qr.title')} />
        <Markup
          className='text-[20px] text-gray-500 text-center mt-4'
          content={t('qr.description')}
        />
      </div>
      <div className='flex sm:flex-row flex-col-reverse justify-between items-stretch container gap-4'>
        <div className='lg:w-4/12 md:w-5/12 sm:w-6/12 w-full bg-white rounded-[16px] p-8 h-full'>
          <InputTextField
            label={`${t('p2p-page.mainForm.surname')} ${t('p2p-page.mainForm.name')}`}
            name='name'
            value={formik.values.name}
            error={formik.touched?.name && formik.errors.name}
            onChange={(e) => {
              const regex = /^[a-zA-Z\s]*$/
              if (regex.test(e.target.value)) {
                formik.setFieldValue('name', e.target.value)
              }
            }}
          />
          <PhoneInputComponent
            value={formik.values.phone}
            styles='!pt-1 !pb-2'
            onChange={(value: any) => formik.setFieldValue('phone', value)}
            t={t}
            error={formik.touched?.phone && formik.errors.phone}
          />
          <InputTextField
            label='email'
            name='email'
            error={formik.touched?.email && formik.errors.email}
            value={formik.values.email}
            onChange={(e) => {
              formik.setFieldValue('email', e.target.value)
            }}
          />
          {data?.canChangeAmount ? (
            <InputTextField
              label={t('p2p-page.mainForm.amount')}
              name='amount'
              value={formik.values.amount || ''}
              error={formik.touched?.amount && formik.errors.amount}
              inputProps={{
                inputMode: 'numeric',
                maxLength: 10,
              }}
              rightIcon={
                <div className='text-[14px] font-bold px-4 border-l-[1px] border-gray-300'>
                  {data?.currency}
                </div>
              }
              onChange={(e) => {
                const re = /^[0-9.]*(\.[0-9]*)?$/
                if (
                  (e.target.value === '' || re.test(e.target.value)) &&
                  e.target.value.toString().length < 10
                ) {
                  formik.setFieldValue('amount', e.target.value)
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === 'E') {
                  e.preventDefault()
                }
              }}
              onBlur={(e) => {
                formik.handleBlur(e)
              }}
            />
          ) : (
            <div className='flex justify-between items-center py-2'>
              <div className='text-[20px] font-bold'>{t('p2p-page.mainForm.amount')}:</div>
              <div className='text-[20px] font-bold'>
                {data?.amount} {data?.currency}
              </div>
            </div>
          )}
          <div className='py-2'>
            {errors &&
              errors.map((error: { errorMessage: string; errorCode: string }) => (
                <div className='text-red-500 text-[14px]'>{t(error.errorMessage)}</div>
              ))}
          </div>
          <div className='flex items-center mt-4 '>
            <Checkbox
              checked={formik.values.agree}
              onChange={(e) => formik.setFieldValue('agree', e.target.checked)}
              name='agree'
              color='secondary'
              className='rounded-xl !ml-0 !pl-0'
            />
            <span className='text-gray font-nexaLight text-[14px] '>
              {t('qr.agree')}
              <a
                href={data?.projectTermsUrl}
                target='_blank'
                className='!text-secondary font-nexaLight hover:no-underline duration-100 ease-in underline text-[14px] cursor-pointer'
                rel='noreferrer'
              >
                {t('qr.terms')}
              </a>
              <span className='text-red-700 ml-1'>*</span>
            </span>
          </div>
          <Button
            type='submit'
            variant='contained'
            disabled={isDisabled}
            className='w-full mt-4'
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            {t('qr.button')}
          </Button>
        </div>
        <div className='lg:w-8/12 md:w-7/12 sm:w-6/12 w-full bg-white rounded-[16px] py-4 h-full'>
          {data?.companyName && (
            <div className='px-8 pb-4 border-b-[1px] border-gray-300'>
              <div className='text-2xl font-bold'>{data?.companyName}</div>
            </div>
          )}
          <div className='flex flex-col px-8 py-4 justify-center items-start'>
            <div className='text-2xl '>{data?.productName}</div>
            <div className='text-xl font-nexaLight'>{data?.description}</div>
          </div>
        </div>
      </div>
      <div className='flex flex-row items-center justify-between w-full container'>
        <img src={VSMG} alt='vsmg' className='sm:my-[65px] my-8 ' />
        <img src={Secure} alt='secure3d' className='sm:my-[65px] my-8' />
      </div>
    </div>
  )
}

export default PayEcommercePage
