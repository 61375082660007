import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { IUtilitiesCategory } from '../../types'
import { UtilityCategoriesEnum, UtilityCategoriesText } from '../../../../../utils/enum'

interface CategoryListProps {
  categories?: IUtilitiesCategory[]
  handleCategory: (id: string) => void
  category: string | null
}

const CategoryList: React.FC<CategoryListProps> = ({ categories, handleCategory, category }) => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-col mb-4 mt-1 bg-white p-4 w-full rounded-[12px] max-sm:hidden'>
      {_.map(categories, (item, index) => {
        const imageTitleSplit = item.image.title.split('.')
        const imageType = imageTitleSplit[imageTitleSplit.length - 1]
        const name =
          item.name in UtilityCategoriesEnum
            ? UtilityCategoriesText[item.name as UtilityCategoriesEnum].text
            : item.name
        return (
          <button
            key={index}
            type='button'
            tabIndex={0}
            onClick={() => (category === item.id ? handleCategory('') : handleCategory(item.id))}
            className={`flex flex-row w-full items-center py-2 px-3 rounded-[12px] my-2 hover:bg-[#B3E6DE82] cursor-pointer transition-all duration-300 ease-in-out border-solid border-[1px]${
              category === item.id ? ' bg-[#B3E6DE52] border-secondary' : ''
            }`}
          >
            <img
              src={`data:image/${imageType === 'svg' ? 'svg+xml' : imageType};base64,${
                item.image.base64
              }`}
              alt=''
              className='h-[24px]'
            />
            <Typography
              variant='body1'
              className='!text-[14px] !font-nexaBold !ml-4 text-center truncate'
            >
              {t(`${name}`)}
            </Typography>
          </button>
        )
      })}
    </div>
  )
}
export default CategoryList
