import React, { useCallback } from 'react'
import ReactInputMask from 'react-input-mask'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Payment from 'payment'
import { Markup } from 'interweave'
import { useTranslation } from 'react-i18next'
import InputTextField from './InputTextField'
import Card from '../assets/svg/card.svg'
import ClearX from '../assets/svg/clear-x.svg'

interface CardFormProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  name?: string
  id: string
  placeholder: string
  error?: string
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  handleClear: () => void
  autocomplete?: string
  tabIndex?: number
}

const defaultProps: Partial<CardFormProps> = {
  name: '',
  error: '',
  autocomplete: '',
}

const RightIcon = (value: string, onClick: () => void) => {
  if (value && value?.length > 0) {
    return (
      <div onClick={onClick} role='button' tabIndex={-1} onKeyDown={onClick}>
        <img src={ClearX} alt='close' className='w-8' />
      </div>
    )
  }
  return <img src={Card} alt='visa' className='w-8' />
}

const Input = ({
  value,
  label,
  autoComplete,
  onClick,
  error,
}: {
  value: string
  label: string
  autoComplete: string | undefined
  onClick: () => void | undefined
  error: string | undefined
}) => {
  return (
    <InputTextField
      label={label}
      autoComplete={autoComplete}
      x-autocompletetype={autoComplete}
      sx={{
        backgroundColor: 'white',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: error && 'red',
          },
          '&:hover fieldset': {
            borderColor: error ? 'red' : '#40C1AC',
          },
          '&.Mui-focused fieldset': {
            borderColor: error ? 'red' : '#40C1AC',
          },
        },
      }}
      rightIcon={RightIcon(value, onClick)}
    />
  )
}

const CreditCardMask = ({
  value,
  handleClear,
  onChange,
  onFocus,
  name,
  id,
  placeholder,
  onBlur,
  error,
  autocomplete,
  tabIndex,
}: CardFormProps) => {
  const { t } = useTranslation()
  const ref = React.useRef<HTMLInputElement>(null)
  const getMask = useCallback((number: string) => {
    const issuer = Payment.fns.cardType(number) || 'unknown'
    let mask = '9999 9999 9999 9999'
    if (issuer === 'amex') {
      mask = '9999 999999 99999'
    } else if (issuer === 'dinersclub') {
      mask = '9999 999999 9999'
    } else if (['hipercard', 'mastercard', 'visa'].includes(issuer)) {
      mask = '9999 9999 9999 9999'
    }
    return mask
  }, [])

  return (
    <div className='w-full flex flex-col'>
      <ReactInputMask
        mask={getMask(value)}
        inputRef={ref}
        maskPlaceholder={null}
        value={value}
        tabIndex={tabIndex || 0}
        type='tel'
        id={id}
        x-autocompletetype={autocomplete}
        name={name}
        autoComplete={autocomplete}
        placeholder={value ? placeholder : '•••• •••• •••• ••••'}
        className='w-full'
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        beforeMaskedStateChange={({ nextState, currentState }) => {
          // eslint-disable-next-line no-shadow
          const { value } = nextState
          if (currentState?.value && currentState?.value?.length > 14) {
            return {
              value: currentState?.value,
              selection: {
                start: 19,
                end: 19,
              },
            }
          }
          return {
            value,
            selection: {
              start: 19,
              end: 19,
            },
          }
        }}
      >
        {Input({
          value,
          label: placeholder,
          autoComplete: autocomplete,
          onClick: handleClear,
          error,
        })}
      </ReactInputMask>
      <div className='text-red-500 text-xs'>{error ? <Markup content={t(`${error}`)} /> : ' '}</div>
    </div>
  )
}

CreditCardMask.defaultProps = defaultProps

export default CreditCardMask
