import React, { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getUtilitiesPayStatus } from '../../utils/api'

const CheckPage = () => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const operationId = searchParams.get('operationId') || ''
  const navigate = useNavigate()
  const { data, isError } = useQuery(
    ['utilitiesPayStatus'],
    () => getUtilitiesPayStatus(operationId),
    {
      refetchInterval: 5000,
    },
  )
  useEffect(() => {
    if (data?.ok === false) {
      navigate('/error', { state: { location: '/utilities' } })
    }
    if (data?.result?.status === 'OK') {
      setLoading(false)
      navigate('/utilities/success', { state: { location: '/utilities', result: data?.result } })
    }
    if (data?.result?.status === 'ERROR') {
      setLoading(false)
      navigate('/error', { state: { location: '/utilities' } })
    } else {
      setLoading(true)
    }
  }, [data])

  if (isError) {
    navigate('/error', { state: { location: '/utilities' } })
  }

  if (loading) {
    return (
      <div className='flex-1 flex items-center justify-center'>
        <CircularProgress color='secondary' size={120} thickness={4} />
      </div>
    )
  }

  return (
    <div className='flex-1 flex items-center justify-center'>
      <CircularProgress color='secondary' size={120} thickness={4} />
    </div>
  )
}

export default CheckPage
