import React, { useEffect } from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { Button, CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { UtilitiesSteps } from '../../../../../utils/enum'
import InputTextField from '../../../../../components/InputTextField'
import ArrowLeft from '../../../../../assets/svg/arrow-left.svg'
import GoUp from '../../../../../assets/svg/go-up.svg'
import { IUtilitiesProduct } from '../../types'
import Clock from '../../../../../assets/svg/clock.svg'

interface CategoryFormProps {
  isLoading: boolean
  products?: IUtilitiesProduct[]
  setStep: (step: UtilitiesSteps) => void
  activeProduct?: IUtilitiesProduct | null
  setActiveProduct: (product: IUtilitiesProduct | null) => void
  handleInitTransaction: (identifier: string, formik: any) => void
  hasNextPage?: boolean
  handleLoadMore: () => void
  providersLoading: boolean
  activeImage: any
}

const CategoryForm: React.FC<CategoryFormProps> = ({
  isLoading,
  products,
  setStep,
  activeProduct,
  setActiveProduct,
  handleInitTransaction,
  hasNextPage,
  handleLoadMore,
  providersLoading,
  activeImage,
}) => {
  const imageTitleSplit =
    activeProduct?.image?.title.split('.') || activeImage?.title.split('.') || []
  const imageType = imageTitleSplit[imageTitleSplit.length - 1] || 'png'
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      identifier: '',
    },
    validationSchema: Yup.object({
      identifier: Yup.string().required('p2p-page.error.required'),
    }),
    onSubmit: (values) => {
      handleInitTransaction(values.identifier, formik)
    },
  })
  useEffect(() => {
    if (!activeProduct) {
      formik.resetForm()
    }
  }, [activeProduct])
  if (isLoading || providersLoading) {
    return (
      <div className='w-full flex flex-col items-center justify-center bg-white mb-4 p-4 px-6 h-full rounded-[12px]'>
        <CircularProgress size={24} thickness={4} color='primary' />
      </div>
    )
  }

  return (
    <div className='min-h-full flex-1 h-full'>
      {!activeProduct ? (
        <div className=' flex flex-col bg-white mb-4 p-4 px-6 rounded-[12px] relative min-h-[100%]'>
          {providersLoading && (
            <div className='absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex items-center justify-center'>
              <CircularProgress size={24} thickness={4} color='primary' />
            </div>
          )}
          <Typography variant='h3'>{t('utilities.categoryForm.title')}</Typography>
          <div className='text-[14px] text-blueGray'>{t('utilities.categoryForm.subtitle')}</div>
          <div className='flex flex-row flex-wrap flex-grow items-stretch mt-4 gap-[1%] max-sm:justify-between '>
            {products &&
              _.map(products, (item: IUtilitiesProduct) => {
                const imgTitle = item.image?.title.split('.') || activeImage?.title.split('.') || []
                const imgType = imgTitle[imgTitle.length - 1] || 'svg'
                return (
                  <button
                    type='button'
                    key={item.id}
                    className='flex flex-col lg:w-[23%] bg-white md:w-[31%] w-[48%] my-2 min-h-[120px] items-center rounded-[12px] shadow-md justify-center hover:bg-[#B3E6DE82] cursor-pointer transition-all duration-300 ease-in-out'
                    onClick={() => setActiveProduct(item)}
                  >
                    <div className='h-[64px] flex items-center justify-center'>
                      <img
                        src={`data:image/${imgType === 'svg' ? 'svg+xml' : 'png'};base64,${
                          item?.image?.base64 || activeImage?.base64
                        }`}
                        className={`${
                          item?.image?.base64
                            ? 'h-[32px] w-[32px]'
                            : ' h-[36px] w-[36px] rounded-[32px] p-[6px] bg-contain'
                        }`}
                        alt=''
                      />
                    </div>
                    <div className='mt-3 mb-4 text-center max-w-[80%]'> {item?.title} </div>
                  </button>
                )
              })}
            {products?.length === 0 && (
              <div
                className='flex flex-row bg-white h-[64px] items-center rounded-[16px] shadow-md justify-center gap-4 pr-8 pl-4'
                style={{
                  border: '1px solid #EDEFF5',
                  transition: 'all 0.3s ease-in-out',
                  animation: 'fadeIn 0.5s ease-in-out',
                }}
              >
                <img src={Clock} className='h-[36px] w-[36px]' alt='' />
                <div className='text-center max-w-[80%] truncate text-[#696F8C] text-[14px]'>
                  {t('utilities.coming-soon')}
                </div>
              </div>
            )}
          </div>
          {hasNextPage && (
            <div className='flex  justify-center w-full mt-4 flex-row items-center gap-2'>
              <div className='flex flex-row max-w-[342px] items-center justify-center'>
                <Button
                  variant='contained'
                  onClick={() => {
                    handleLoadMore()
                  }}
                  className=' w-full !rounded-[12px]'
                >
                  {t('utilities.categoryForm.loadMore')}
                </Button>
                <button
                  type='button'
                  className=' cursor-pointer pl-2'
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                  }
                  aria-label='Scroll to top'
                >
                  <img src={GoUp} alt='' className='h-[64px]' />
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='w-full flex flex-col items-center justify-center bg-white mb-4 p-8  h-full rounded-[12px] transition ease-in-out duration-700'>
          <div className='flex flex-row justify-between w-full'>
            <div className='w-1/3'>
              <img
                src={ArrowLeft}
                alt=''
                className='cursor-pointer'
                onClick={() => {
                  setActiveProduct(null)
                }}
              />
            </div>
            <div className='w-1/3 flex items-center justify-center'>
              <img
                src={`data:image/${imageType === 'svg' ? 'svg+xml' : 'png'};base64,${
                  activeProduct?.image?.base64 || activeImage?.base64
                }`}
                className={` ${
                  activeProduct?.image?.base64
                    ? 'h-[32px] w-[32px]'
                    : ' h-[36px] w-[36px] rounded-[32px] bg-[#D9F3EE] p-[6px]'
                } `}
                alt=''
              />
            </div>
            <div className='w-1/3 flex items-center justify-center' />
          </div>
          <div className='mt-3 font-nexaBold text-[20px] text-center'>
            {activeProduct?.title || ''}
          </div>
          <div className='sm:w-8/12 max-w-[342px] w-full my-4'>
            <InputTextField
              label={activeProduct?.invoiceInputPlaceholder || ''}
              placeholder={activeProduct?.invoiceInputPlaceholder || ''}
              type='text'
              variant='outlined'
              value={formik.values.identifier}
              onBlur={(e) => {
                formik.handleBlur(e)
              }}
              onChange={(e) => {
                formik.setFieldValue('identifier', e.target.value)
              }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              error={
                !!formik.values.identifier && formik.touched.identifier && formik.errors.identifier
              }
            />
          </div>
          <Button
            variant='contained'
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={!formik.isValid || !formik.values.identifier}
            className='sm:w-8/12 !max-w-[342px] w-full mt-4'
            style={{
              backgroundColor: '#40C1AC',
              color: '#fff',
            }}
          >
            {t('utilities.categoryForm.next')}
          </Button>
        </div>
      )}
    </div>
  )
}
export default React.memo(CategoryForm)
