import React, { type FC } from 'react'
import { Checkbox } from '@mui/material'
import { Switch } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import InputTextField from '../../../../../components/InputTextField'

interface IInvoicesAmountForm {
  fields: any
  formik: any
  hasAutoCounter: boolean
}

const InvoicesAmountForm: FC<IInvoicesAmountForm> = ({ fields, formik, hasAutoCounter }) => {
  const { t } = useTranslation()
  const getMinimumValue = (payment: any) => {
    if (payment.amountMask === 1) return payment.amount
    if (payment.amountMin === 0) return 1
    return payment.amountMin
  }
  return (
    <div className='sm:w-6/12 max-w-[342px] w-full flex flex-col '>
      {fields &&
        fields?.map((field: any, index: number) => {
          const counterNameArr = field?.counterName.split('|')
          const counterOldArr = field?.counterOld.split('|')
          const minimumValue = getMinimumValue(field)
          const counters = field.totalCounters
            ? counterNameArr.map((name: string, indexNumber: number) => ({
                name,
                oldValue: counterOldArr[indexNumber] || '0',
                value: '0',
                visible: true,
              }))
            : null
          return (
            <div
              className='px-4 py-6 rounded-[24px] shadow-md my-4 bg-white'
              key={`key:${index + 1}:${field?.id}`}
            >
              <div className='w-full items-center flex'>
                {fields.length > 1 && (
                  <Checkbox
                    checked={formik.values[field.id].checked}
                    onChange={(e) => {
                      formik.setFieldValue(`${fields[index].id}.checked`, e.target.checked)
                    }}
                    color='secondary'
                    className='!p-0 !mr-2'
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                <span className='text-[14px] font-[600]'>{field.destination}</span>
              </div>
              <InputTextField
                disabled={!formik.values[field.id].checked || field.amountMask === 0}
                label={t('utilities.paymentForm.amount')}
                value={formik.values[field.id].value || ''}
                className='w-full'
                onKeyDown={(e) => {
                  if (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === 'E') {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => {
                  const re = /^[0-9]*\.?[0-9]{0,2}$/
                  if (e.target.value === '' || re.test(e.target.value)) {
                    formik.setFieldValue(
                      `${fields[index].id}.value`,
                      e.target.value === '' ? 0 : e.target.value,
                    )
                  }
                }}
                error={formik?.errors[field?.id]?.value}
              />
              <div className='flex flex-col'>
                {minimumValue && (
                  <span className='text-[12px] my-1 text-gray-500'>
                    {`${t('utilities.paymentForm.minimumValue')} ${minimumValue} MDL`}
                  </span>
                )}
                <div />
                {field?.customerFeeMetadata &&
                  field?.customerFeeMetadata.map(
                    (fee: any) =>
                      fee?.feeRule && (
                        <span className='text-[12px] my-1 text-gray-500'>
                          {`${t('utilities.paymentForm.fee')}: ${fee?.feeRule}`}
                        </span>
                      ),
                  )}
              </div>
              {counters &&
                counters.map((counter: any, counterIndex: number) => (
                  <div
                    className='w-full items-start flex flex-col'
                    key={`key:${index + 1}:${counterIndex + 1}:${field?.id}`}
                  >
                    {hasAutoCounter && (
                      <div className='flex flex-row w-full justify-between items-center'>
                        <span className='text-[14px] font-[600]'>
                          {t('utilities.paymentForm.counterName')} {counter.name}
                        </span>
                        <Switch
                          checked={formik.values[field.id].counters[counterIndex]?.visible !== true}
                          onChange={() => {
                            formik.setFieldValue(
                              `${fields[index].id}.counters[${counterIndex}].visible`,
                              !formik.values[field.id].counters[counterIndex]?.visible,
                            )
                          }}
                          className={`${
                            !formik.values[field.id].counters[counterIndex]?.visible
                              ? 'bg-secondary'
                              : 'bg-gray-200'
                          } relative inline-flex flex-shrink-0 h-6 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200`}
                        >
                          <span
                            className={`${
                              !formik.values[field.id].counters[counterIndex]?.visible
                                ? 'translate-x-[18px]'
                                : 'translate-x-[2px]'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition mt-[2px]`}
                          />
                        </Switch>
                      </div>
                    )}
                    {counter.name && (
                      <span className='text-[14px] font-[600]'>
                        {t('utilities.paymentForm.counterName')} {counter.name}
                      </span>
                    )}
                    <InputTextField
                      disabled={!formik.values[field.id].counters[counterIndex]?.visible}
                      label={t('utilities.paymentForm.counterValue')}
                      value={formik.values[field.id].counters[counterIndex]?.value}
                      className='w-full !mb-0'
                      onKeyDown={(e) => {
                        if (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === 'E') {
                          e.preventDefault()
                        }
                      }}
                      onChange={(e) => {
                        const re = /^[ \d,]+(\.\d*)?$/
                        if (re.test(e.target.value) || e.target.value === '')
                          formik.setFieldValue(
                            `${field.id}.counters[${counterIndex}].value`,
                            e.target.value,
                          )
                      }}
                      error={
                        formik?.errors[field?.id]?.counters
                          ? formik?.errors[field?.id]?.counters[counterIndex]?.value
                          : ''
                      }
                    />
                    <span className='text-[12px] my-1'>
                      {t('utilities.paymentForm.oldValue')} {counter?.oldValue}
                    </span>
                  </div>
                ))}
            </div>
          )
        })}
    </div>
  )
}
export default InvoicesAmountForm
