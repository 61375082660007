import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SuccesForm from '../../components/SuccesForm'

const SuccessPage = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  return (
    <SuccesForm
      handleSuccess={() => {
        navigate(state?.location || '/')
      }}
    />
  )
}

export default SuccessPage
