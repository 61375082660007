import React, { type FC } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { IUtilitiesCategory } from '../types'
import { CategoryCard } from './components'
import { UtilitiesSteps } from '../../../../utils/enum'

interface IIntroForm {
  handleCategoryPress: (id: string) => void
  setStep: (step: UtilitiesSteps) => void
  categoriesList?: IUtilitiesCategory[]
}

const IntroForm: FC<IIntroForm> = ({ handleCategoryPress, categoriesList, setStep }) => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-col items-center container w-full h-full py-16 px-4 '>
      <Typography variant='h3' className='text-center '>
        {t('utilities.introForm.title')}
      </Typography>
      <Typography variant='h5' className='text-center max-sm:!text-[14px]'>
        {t('utilities.introForm.subtitle')}
      </Typography>
      <div className='flex sm:flex-wrap flex-col sm:flex-row items-center gap-4 sm:py-16 py-8 w-full justify-center'>
        {categoriesList &&
          categoriesList?.map((item: IUtilitiesCategory, index: number) => (
            <CategoryCard
              key={`${item.id}-${index + 1}`}
              item={item}
              handleClick={(id) => {
                handleCategoryPress(id)
                setStep(UtilitiesSteps.TRANSACTION_INIT)
              }}
            />
          ))}
      </div>
    </div>
  )
}

export default IntroForm
