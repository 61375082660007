import axios, { AxiosRequestConfig } from 'axios'
import { IP2PCard } from './interfaces'
import { CurrencyEnum, CurrencyType } from './enum'
import i18next from '../i18n/config'

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || 'https://maibpay-gateway.maib.md/api',
  // baseURL: 'https://test-gateway-payhub.maib.md/api',
})

client.interceptors.request.use((config: any) => {
  // Determine the langCode based on the i18next language
  let langCode
  switch (i18next.language) {
    case 'ru':
      langCode = 3
      break
    case 'en':
      langCode = 2
      break
    default:
      langCode = 1
      break
  }

  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      langCode,
    },
  }

  return newConfig
})
const langCode = () => {
  const { language } = i18next
  switch (language) {
    case 'ru':
      return 3
    case 'en':
      return 2
    default:
      return 1
  }
}
// P2P
const getSender = (values: IP2PCard) => ({
  card: values.sender.number.split(' ').join(''),
  cardCvv: 'cvc' in values.sender ? values.sender.cvc : null,
  cardYear: 'expiry' in values.sender ? values.sender.expiry.split('/')[1] : null,
  cardMonth:
    'expiry' in values.sender ? parseInt(values.sender.expiry.split('/')[0], 10).toString() : null,
  birthDate: 'birthDate' in values.sender ? values.sender.birthDate : null,
  firstName: 'firstName' in values.sender ? values.sender?.firstName : null,
  lastName: 'lastName' in values.sender ? values.sender?.lastName : null,
  country: 'address' in values.sender ? values.sender.address : null,
})

const getReceiver = (values: IP2PCard) => ({
  card: values.receiver.number.split(' ').join(''),
  firstName: 'firstName' in values.receiver ? values.receiver?.firstName : null,
  lastName: 'lastName' in values.receiver ? values.receiver?.lastName : null,
  country: 'country' in values.receiver ? values.receiver.country : null,
  birthDate: 'birthDate' in values.receiver ? values.receiver.birthDate : null,
})

export const p2p = (id: string, config?: AxiosRequestConfig) =>
  client.get(`/p2p/${id}`, config).then((res) => res.data)

export const initSesion = (service: string, config?: AxiosRequestConfig) =>
  client
    .post(
      '/data/init-session',
      {
        product: service,
        channel: 'web',
      },
      config,
    )
    .then((res) => res.data)

export const checkCard = (data: any, config?: AxiosRequestConfig) =>
  client.post('/data/check-card', data, config).then((res) => res.data)

export const getVersion = (config?: AxiosRequestConfig) =>
  client.get('/version', config).then((res) => res.data)

export const p2pInit = (
  values: any,
  operationId: string,
  amount: string,
  ref: string | null,
  config?: AxiosRequestConfig,
) =>
  client
    .post(
      `/p2p/card/init?language=${langCode()}`,
      {
        operationId,
        amount: values.amount.toString(),
        ccy: CurrencyType[values?.currency as CurrencyEnum].ccy,
        acceptVisaAlias: values.acceptVisaAlias,
        referenceCode: ref,
        sender: getSender(values),
        recipient: getReceiver(values),
      },
      {
        ...config,
        headers: {
          recaptchaTokenV2: values.recaptchaTokenV2,
          recaptchaTokenV3: values.recaptchaTokenV3,
        },
      },
    )
    .then((res) => res.data)

export const sendOtp = (
  isResend: boolean,
  operationId: string,
  values: any,
  config?: AxiosRequestConfig,
) =>
  client
    .post(
      `/p2p/card/${isResend ? 'resend' : 'send'}-otp?language=${langCode()}`,
      {
        operationId,
        amount: values.amount.toString(),
        ccy: CurrencyType[values?.currency as CurrencyEnum].ccy,
        acceptVisaAlias: values.acceptVisaAlias,
        sender: getSender(values),
        recipient: getReceiver(values),
      },
      config,
    )
    .then((res) => res.data)

export const confirmP2p = (
  operationId: string,
  values: any,
  otp: any,
  config?: AxiosRequestConfig,
) =>
  client
    .post(
      `/p2p/card/confirm?language=${langCode()}`,
      {
        operationId,
        amount: values.amount.toString(),
        ccy: CurrencyType[values?.currency as CurrencyEnum].ccy,
        otp,
        acceptVisaAlias: values.acceptVisaAlias,
        sender: getSender(values),
        recipient: getReceiver(values),
      },
      config,
    )
    .then((res) => res.data)

export const phoneInit = (
  values: any,
  operationId: string,
  amount: string,
  ref: string | null,
  config?: AxiosRequestConfig,
) =>
  client
    .post(
      `/p2p/phone/init?language=${langCode()}`,
      {
        operationId,
        amount: values.amount.toString(),
        ccy: CurrencyType[values?.currency as CurrencyEnum].ccy,
        acceptVisaAlias: values.acceptVisaAlias,
        referenceCode: ref,
        sender: getSender(values),
        recipient: {
          phone: 'phone' in values.receiver ? values.receiver.phone : null,
        },
      },
      {
        ...config,
        headers: {
          recaptchaTokenV2: values.recaptchaTokenV2,
          recaptchaTokenV3: values.recaptchaTokenV3,
        },
      },
    )
    .then((res) => res.data)

export const phoneConfirm = (
  values: any,
  operationId: string,
  otp: any,
  config?: AxiosRequestConfig,
) =>
  client
    .post(
      `/p2p/phone/confirm?language=${langCode()}`,
      {
        operationId,
        amount: values.amount.toString(),
        otp,
        ccy: CurrencyType[values?.currency as CurrencyEnum].ccy,
        acceptVisaAlias: values.acceptVisaAlias,
        // recaptchaTokenV2: values.recaptchaTokenV2,
        // recaptchaTokenV3: values.recaptchaTokenV3,
        sender: getSender(values),
        recipient: {
          phone: 'phone' in values.receiver ? values.receiver.phone : null,
        },
      },
      config,
    )
    .then((res) => res.data)

export const phoneSendOtp = (
  isResend: boolean,
  operationId: string,
  values: any,
  config?: AxiosRequestConfig,
) =>
  client
    .post(
      `/p2p/phone/${isResend ? 'resend' : 'send'}-otp?language=${langCode()}`,
      {
        operationId,
        amount: values.amount.toString(),
        ccy: CurrencyType[values?.currency as CurrencyEnum].ccy,
        acceptVisaAlias: values.acceptVisaAlias,
        sender: getSender(values),
        recipient: {
          phone: 'phone' in values.receiver ? values.receiver.phone : null,
        },
      },
      config,
    )
    .then((res) => res.data)

export const checkP2P = (operationId: string, config?: AxiosRequestConfig) =>
  client.get(`/p2p/transactions/check?operationId=${operationId}`, config).then((res) => res.data)

// T2C
export const T2cInitSession = (config?: AxiosRequestConfig) =>
  client
    .post(
      '/data/init-session',
      {
        product: 't2c',
        channel: 'web',
      },
      config,
    )
    .then((res) => res.data)

export const t2cInit = (
  operationId: string,
  values: any,
  ref: string | null,
  config?: AxiosRequestConfig,
) => {
  const cardNr = values?.cardNumber.split('•').join('')
  return client
    .post(
      `/t2c/init?language=${langCode()}`,
      {
        operationId,
        serviceId: values.provider.toString(),
        amount: values.amount.toString(),
        currencyId: values.currency.toString(),
        transferUIN: values.code,
        fiscalCode: values.idnp,
        accountValue: cardNr, // remove spaces
        referenceCode: ref,
      },
      config,
    )
    .then((res) => res.data)
}

export const t2cSendOtp = (isResend: boolean, operationId: string, config?: AxiosRequestConfig) =>
  client
    .post(
      `/t2c/${isResend ? 'resend' : 'send'}-otp?language=${langCode()}`,
      { operationId },
      config,
    )
    .then((res) => res.data)

export const t2cMoneyRemittance = (operationId: string, config?: AxiosRequestConfig) =>
  client
    .post(`/t2c/money-remittance?language=${langCode()}`, { operationId }, config)
    .then((res) => res.data)

export const t2cCurrencies = (operationId: string, config?: AxiosRequestConfig) =>
  client
    .post(`/t2c/currencies?language=${langCode()}`, { operationId }, config)
    .then((res) => res.data)

export const t2cConfirm = (
  operationId: string,
  operationOtp: string,
  values: any,
  config?: AxiosRequestConfig,
) => {
  const costumerInputData = values
    ? Object.entries(values).map(([key, value]) => ({
        fieldName: key,
        fieldValue: value,
      }))
    : null
  return client
    .post(
      `/t2c/confirm?language=${langCode()}`,
      {
        operationId,
        operationOtp,
        customerInputData: costumerInputData?.length ? costumerInputData : null,
      },
      config,
    )
    .then((res) => res.data)
}
export const t2cPdfReceipt = (operationId: string, config?: AxiosRequestConfig) =>
  client
    .post(`/t2c/generate-receipt?language=${langCode()}`, { operationId }, config)
    .then((res) => res.data)

// visaAlias

export const visaAliasInit = (phone: string, sessionId: string, config?: AxiosRequestConfig) => {
  const phoneNumber = phone.replace(/[+ ]/g, '')
  return client
    .post(
      `/visa-alias/init?language=${langCode()}`,
      { phone: phoneNumber, operationId: sessionId },
      config,
    )
    .then((res) => res.data)
}
export const visaAliasResend = (phone: string, sessionId: string, config?: AxiosRequestConfig) => {
  const phoneNumber = phone.replace(/[+ ]/g, '')
  return client
    .post(
      `/visa-alias/resend-otp?language=${langCode()}`,
      { phone: phoneNumber, operationId: sessionId },
      config,
    )
    .then((res) => res.data)
}
export const visaAliasConfirm = (otp: string, sessionId: string, config?: AxiosRequestConfig) =>
  client
    .post(`/visa-alias/confirm?language=${langCode()}`, { otp, operationId: sessionId }, config)
    .then((res) => res.data)
export const visaAliasUnsubscribe = (sessionId: string, config?: AxiosRequestConfig) =>
  client.post('/visa-alias/unsubscribe', { operationId: sessionId }, config).then((res) => res.data)
export const visaAliasSave = (cardId: number, sessionId: string, config?: AxiosRequestConfig) =>
  client
    .post('/visa-alias/subscribe', { cardId, operationId: sessionId }, config)
    .then((res) => res.data)
export const visaAliasModify = (cardId: number, sessionId: string, config?: AxiosRequestConfig) =>
  client
    .post('/visa-alias/update', { cardId, operationId: sessionId }, config)
    .then((res) => res.data)
// MainPage

export const getCurrencies = (config?: AxiosRequestConfig) => {
  const today = new Date()
  const day = today.getDate().toString().padStart(2, '0') // Get day as 2-digit string
  const month = (today.getMonth() + 1).toString().padStart(2, '0') // Get month as 2-digit string (Note: month index is 0-based)
  const year = today.getFullYear()
  const formattedDate = `${year}${month}${day}`

  return client.get(`/data/rates/${formattedDate.toString()}`, config).then((res) => res.data)
}

// Utilities page

export const utilitiesInitSession = (config?: AxiosRequestConfig) =>
  client
    .post(
      '/data/init-session',
      {
        product: 'utilities',
        channel: 'web',
      },
      config,
    )
    .then((res) => res.data)

export const getUtilitiesCategories = (config?: AxiosRequestConfig) =>
  client.get('/utilities/providers/categories', config).then((res) => res.data)

export const getUtilitiesProviders = (categoryId: string, search: string, page = 1, limit = 10) =>
  client
    .get(
      `/utilities/providers?categoryId=${categoryId}&pageNumber=${page}&pageSize=${limit}&search=${search}&lang=${langCode()}`,
    )
    .then((res) => res.data)

export const postUtilitiesProvider = (
  providerId: string,
  operationId: string,
  invoiceKey: string,
  config?: AxiosRequestConfig,
) =>
  client
    .post(
      `/utilities/providers/${providerId}`,
      {
        operationId,
        lang: langCode(),
        invoiceKey,
      },
      config,
    )
    .then((res) => res.data)

export const postRecalculate = (operationId: string, payments: any, config?: AxiosRequestConfig) =>
  client
    .post(
      '/utilities/pay/recalculate',
      {
        operationId,
        payments,
      },
      config,
    )
    .then((res) => res.data)

export const postUtilitiesPay = (operationId: string, payments: any, config?: AxiosRequestConfig) =>
  client
    .post(
      '/utilities/pay/init',
      {
        lang: langCode(),
        operationId,
        cardMonth: payments.expirationDate.split('/')[0],
        cardYear: payments.expirationDate.split('/')[1],
        card: payments.cardNumber.split(' ').join(''),
        cardCvv: payments.cvv,
        firstName: payments?.firstName || 'MaibPay',
        lastName: payments?.lastName || 'MaibPay',
      },
      config,
    )
    .then((res) => res.data)

export const getUtilitiesPayStatus = (operationId: string, config?: AxiosRequestConfig) =>
  client.get(`/utilities/pay/check?operationId=${operationId}`, config).then((res) => res.data)
// QR Code
export const getQrInit = (operationId: string, config?: AxiosRequestConfig) =>
  client.get(`/qr/init/${encodeURIComponent(operationId)}`, config).then((res) => res.data)
export const getQrEcommerceInit = (operationId: string, config?: AxiosRequestConfig) =>
  client.get(`/qr/ecommerce/init/${operationId}`, config).then((res) => res.data)
export const postQrEcommerceConfirm = (
  operationId: string,
  data: any,
  config?: AxiosRequestConfig,
) => client.post(`/qr/ecommerce/confirm/${operationId}`, data, config).then((res) => res.data)
