// export const MainError = [
//   'MaibPay.P2P-PaymentProcessing-160',
//   'MaibPay.P2P-PaymentProcessing-200',
//   'MaibPay.P2P-PaymentProcessing-201',
//   'MaibPay.P2P-PaymentProcessing-202',
//   'MaibPay.P2P-PaymentProcessing-203',
//   'MaibPay.P2P-PaymentProcessing-204',
//   'MaibPay.P2P-PaymentProcessing-205',
//   'MaibPay.P2P-PaymentProcessing-207',
//   'MaibPay.P2P-PaymentProcessing-208',
//   'MaibPay.P2P-PaymentProcessing-209',
//   'MaibPay.P2P-PaymentProcessing-210',
//   'MaibPay.P2P-PaymentProcessing-300',
//   'MaibPay.P2P-PaymentProcessing-301',
//   'MaibPay.P2P-PaymentProcessing-302',
//   'MaibPay.P2P-PaymentProcessing-303',
//   'MaibPay.P2P-PaymentProcessing-304',
//   'MaibPay.P2P-PaymentProcessing-305',
//   'MaibPay.P2P-PaymentProcessing-306',
//   'MaibPay.P2P-PaymentProcessing-307',
//   'MaibPay.P2P-PaymentProcessing-308',
//   'MaibPay.P2P-PaymentProcessing-309',
//   'MaibPay.P2P-PaymentProcessing-400',
//   'MaibPay.P2P-PaymentProcessing-499',
//   'MaibPay.P2P-PaymentProcessing-500',
//   'MaibPay.P2P-PaymentProcessing-501',
//   'MaibPay.P2P-PaymentProcessing-502',
//   'MaibPay.P2P-PaymentProcessing-503',
//   'MaibPay.P2P-PaymentProcessing-504',
//   'MaibPay.P2P-PaymentProcessing-601',
//   'MaibPay.P2P-PaymentProcessing-602',
//   'MaibPay.P2P-PaymentProcessing-604',
//   'MaibPay.P2P-PaymentProcessing-605',
//   'MaibPay.P2P-PaymentProcessing-606',
//   'MaibPay.P2P-PaymentProcessing-680',
//   'MaibPay.P2P-PaymentProcessing-681',
//   'MaibPay.P2P-PaymentProcessing-700',
//   'MaibPay.P2P-PaymentProcessing-800',
//   'MaibPay.P2P-PaymentProcessing-900',
//   'MaibPay.P2P-PaymentProcessing-901',
//   'MaibPay.P2P-PaymentProcessing-902',
//   'MaibPay.P2P-PaymentProcessing-903',
//   'MaibPay.P2P-PaymentProcessing-904',
//   'MaibPay.P2P-PaymentProcessing-905',
//   'MaibPay.P2P-PaymentProcessing-906',
//   'MaibPay.P2P-PaymentProcessing-907',
//   'MaibPay.P2P-PaymentProcessing-908',
//   'MaibPay.P2P-PaymentProcessing-909',
//   'MaibPay.P2P-PaymentProcessing-910',
//   'MaibPay.P2P-PaymentProcessing-911',
//   'MaibPay.P2P-PaymentProcessing-912',
//   'MaibPay.P2P-PaymentProcessing-913',
//   'MaibPay.P2P-PaymentProcessing-914',
//   'MaibPay.P2P-PaymentProcessing-915',
//   'MaibPay.P2P-PaymentProcessing-916',
//   'MaibPay.P2P-PaymentProcessing-917',
//   'MaibPay.P2P-PaymentProcessing-918',
//   'MaibPay.P2P-PaymentProcessing-919',
//   'MaibPay.P2P-PaymentProcessing-920',
//   'MaibPay.P2P-PaymentProcessing-921',
//   'MaibPay.P2P-PaymentProcessing-922',
//   'MaibPay.P2P-PaymentProcessing-923',
//   'MaibPay.P2P-PaymentProcessing-950',
// ]

// export const blockedCardError = [
//   'MaibPay.P2P-PaymentProcessing-100',
//   'MaibPay.P2P-PaymentProcessing-101',
//   'MaibPay.P2P-PaymentProcessing-102',
//   'MaibPay.P2P-PaymentProcessing-103',
//   'MaibPay.P2P-PaymentProcessing-104',
//   'MaibPay.P2P-PaymentProcessing-105',
//   'MaibPay.P2P-PaymentProcessing-106',
//   'MaibPay.P2P-PaymentProcessing-107',
//   'MaibPay.P2P-PaymentProcessing-108',
//   'MaibPay.P2P-PaymentProcessing-109',
//   'MaibPay.P2P-PaymentProcessing-110',
//   'MaibPay.P2P-PaymentProcessing-111',
//   'MaibPay.P2P-PaymentProcessing-112',
//   'MaibPay.P2P-PaymentProcessing-113',
//   'MaibPay.P2P-PaymentProcessing-114',
//   'MaibPay.P2P-PaymentProcessing-115',
// ]

// export const InsufficientMoneyInAccount = ['MaibPay.P2P-PaymentProcessing-116']

// export const InactiveCard = ['MaibPay.P2P-PaymentProcessing-125']

// export const CardDetailsError = [
//   'MaibPay.P2P-PaymentProcessing-129',
//   'MaibPay.P2P-402',
//   'MaibPay.P2P-403',
// ]

// export const SuccessError = [
//   'MaibPay.P2P-PaymentProcessing-0',
//   'MaibPay.P2P-PaymentProcessing-1',
//   'MaibPay.P2P-PaymentProcessing-2',
//   'MaibPay.P2P-PaymentProcessing-3',
//   'MaibPay.P2P-PaymentProcessing-4',
//   'MaibPay.P2P-PaymentProcessing-5',
//   'MaibPay.P2P-PaymentProcessing-6',
//   'MaibPay.P2P-PaymentProcessing-7',
// ]

// export const OtpGenerationError = [
//   'MaibPay.P2P-PaymentProcessing-126',
//   'MaibPay.P2P-500',
//   'MaibPay.P2P-501',
//   'MaibPay.P2P-502',
// ]

// export const OtpLimitationError = ['MaibPay.P2P-PaymentProcessing-206']

// export const OtpIncorectError = [
//   'MaibPay.P2P-PaymentProcessing-117',
//   'MaibPay.P2P-PaymentProcessing-161',
//   'MaibPay.P2P-503',
// ]

// export const ValidationErrors = ['MaibPay.P2P-PaymentProcessing-603']

// export const LimitationError = ['MaibPay.P2P-PaymentProcessing-121']

// export const TransactionError = [
//   'MaibPay.P2P-PaymentProcessing-8',
//   'MaibPay.P2P-PaymentProcessing-162',
//   'MaibPay.P2P-PaymentProcessing-163',
//   'MaibPay.P2P-PaymentProcessing-164',
//   'MaibPay.P2P-PaymentProcessing-180',
//   'MaibPay.P2P-PaymentProcessing-199',
//   'MaibPay.P2P-PaymentProcessing-130',
//   'MaibPay.P2P-PaymentProcessing-127',
//   'MaibPay.P2P-PaymentProcessing-128',
//   'MaibPay.P2P-PaymentProcessing-118',
//   'MaibPay.P2P-PaymentProcessing-119',
//   'MaibPay.P2P-PaymentProcessing-120',
//   'MaibPay.P2P-PaymentProcessing-122',
//   'MaibPay.P2P-PaymentProcessing-123',
//   'MaibPay.P2P-PaymentProcessing-124',
//   'MaibPay.P2P-600',
//   'MaibPay.P2P-601',
//   'MaibPay.P2P-602',
//   'MaibPay.P2P-604',
//   'MaibPay.P2P-604',
//   'MaibPay.P2P-400',
//   'MaibPay.P2P-300',
//   'MaibPay.P2P-301',
//   'MaibPay.P2P-200',
//   'MaibPay.P2P-201',
//   'MaibPay.P2P-202',
//   'MaibPay.P2P-100',
//   'MaibPay.P2P-101',
//   'MaibPay.P2P-700',
//   'MaibPay.P2P-701',
//   'MaibPay.P2P-702',
// ]

// export const P2PErrorsValidation = (error: any) => {
//   if (blockedCardError.includes(error)) return 'error.blockedCardError'
//   if (InsufficientMoneyInAccount.includes(error)) return 'error.InsufficientMoneyInAccount'
//   if (InactiveCard.includes(error)) return 'error.InactiveCard'
//   if (CardDetailsError.includes(error)) return 'error.CardDetailsError'
//   // if (SuccessError.includes(error)) return 'error.SuccessError'
//   if (OtpGenerationError.includes(error)) return 'error.OtpGenerationError'
//   if (OtpLimitationError.includes(error)) return 'error.OtpLimitationError'
//   if (OtpIncorectError.includes(error)) return 'error.OtpIncorectError'
//   if (ValidationErrors.includes(error)) return 'error.ValidationErrors'
//   if (LimitationError.includes(error)) return 'error.LimitationError'
//   if (TransactionError.includes(error)) return 'error.TransactionError'
//   if (SessionError.includes(error)) return 'error.SessionError'
//   // if (PaymentProcessingError.includes(error)) return 'error.PaymentProcessingError'
//   if (MainError.includes(error)) return 'error.MainError'
//   if (CaptchaError.includes(error)) return 'error.captchaError'
//   return 'error.defaultError'
// }

// export const SessionError = ['MaibPay.P2P-401']

// export const PaymentProcessingError = ['MaibPay.P2P-800', 'MaibPay.P2P-801', 'MaibPay.P2P-802']

// const PaymentProcessingError = ['maibpay.p2p-16']

// eslint-disable-next-line no-shadow
export enum P2PErrorCodes {
  MainError = 'maibpay.p2p-1',
  BlockedCardError = 'maibpay.p2p-2',
  InsufficientMoneyInAccount = 'maibpay.p2p-3',
  InactiveCard = 'maibpay.p2p-4',
  CardDetailsError = 'maibpay.p2p-5',
  OtpGenerationError = 'maibpay.p2p-11',
  OtpLimitationError = 'maibpay.p2p-12',
  OtpIncorrectError = 'maibpay.p2p-14',
  ValidationErrors = 'maibpay.p2p-15',
  LimitationError = 'maibpay.p2p-10',
  TransactionError = 'maibpay.p2p-16',
  SessionError = 'maibpay.p2p-17',
  CaptchaError = 'captcha-2',
  CaptchaErrorAlt = 'captcha',
  MeToMeError = 'maibpay.p2p-13',
  SameCardsError = 'maibpay.p2p-22',
  NameError = 'maibpay.p2p-7',
  PhoneError = 'maibpay.p2p-8',
  CountryError = 'maibpay.p2p-6',
  ApproveError = 'maibpay.p2p-23',
  BusinessCard = 'maibpay.p2p-24',
  A2AFirstName = 'maib.payments.a2a-29',
  A2ALastName = 'maib.payments.a2a-30',
}

const errorMap = {
  [P2PErrorCodes.MainError]: 'error.MainError',
  [P2PErrorCodes.BlockedCardError]: 'error.blockedCardError',
  [P2PErrorCodes.InsufficientMoneyInAccount]: 'error.InsufficientMoneyInAccount',
  [P2PErrorCodes.InactiveCard]: 'error.InactiveCard',
  [P2PErrorCodes.CardDetailsError]: 'error.CardDetailsError',
  [P2PErrorCodes.OtpGenerationError]: 'error.OtpGenerationError',
  [P2PErrorCodes.OtpLimitationError]: 'error.OtpLimitationError',
  [P2PErrorCodes.OtpIncorrectError]: 'error.OtpIncorectError',
  [P2PErrorCodes.ValidationErrors]: 'error.ValidationErrors',
  [P2PErrorCodes.LimitationError]: 'error.LimitationError',
  [P2PErrorCodes.TransactionError]: 'error.TransactionError',
  [P2PErrorCodes.SessionError]: 'error.SessionError',
  [P2PErrorCodes.CaptchaError]: 'error.captchaError',
  [P2PErrorCodes.CaptchaErrorAlt]: 'error.captchaError',
  [P2PErrorCodes.MeToMeError]: 'error.MeToMeError',
  [P2PErrorCodes.SameCardsError]: 'error.SameCardsError',
  [P2PErrorCodes.NameError]: 'error.NameError',
  [P2PErrorCodes.PhoneError]: 'error.PhoneError',
  [P2PErrorCodes.CountryError]: 'error.CountryError',
  [P2PErrorCodes.ApproveError]: 'error.ApproveError',
  [P2PErrorCodes.BusinessCard]: 'error.BusinessCard',
  [P2PErrorCodes.A2AFirstName]: 'error.a2a.sender.firstName',
  [P2PErrorCodes.A2ALastName]: 'error.a2a.sender.lastName',
}

export const P2PErrorsValidation = (error: P2PErrorCodes | string) => {
  if (Object.values(P2PErrorCodes).includes(error as P2PErrorCodes)) {
    return errorMap[error as P2PErrorCodes]
  }
  return `Error : ${error}`
}

export const T2CBaseError = [
  'MaibPay.T2C-IMTIS-1',
  'MaibPay.T2C-IMTIS-2',
  'MaibPay.T2C-IMTIS-3',
  'MaibPay.T2C-IMTIS-4',
  'MaibPay.T2C-IMTIS-5',
  'MaibPay.T2C-IMTIS-6',
  'MaibPay.T2C-IMTIS-7',
  'MaibPay.T2C-IMTIS-8',
  'MaibPay.T2C-IMTIS-9',
  'MaibPay.T2C-IMTIS-10',
  'MaibPay.T2C-IMTIS-11',
  'MaibPay.T2C-IMTIS-12',
  'MaibPay.T2C-IMTIS-13',
  'MaibPay.T2C-IMTIS-14',
  'MaibPay.T2C-IMTIS-15',
  'MaibPay.T2C-IMTIS-16',
  'MaibPay.T2C-IMTIS-17',
  'MaibPay.T2C-IMTIS-18',
  'MaibPay.T2C-IMTIS-19',
  'MaibPay.T2C-IMTIS-20',
  'MaibPay.T2C-IMTIS-21',
  'MaibPay.T2C-IMTIS-22',
  'MaibPay.T2C-IMTIS-23',
  'MaibPay.T2C-IMTIS-24',
  'MaibPay.T2C-IMTIS-25',
]
export const T2CTechError = [
  'MaibPay.T2C-101',
  'MaibPay.T2C-200',
  'MaibPay.T2C-201',
  'MaibPay.T2C-300',
  'MaibPay.T2C-301',
]

export const T2CErrorsValidation = (error: string) => {
  if (T2CBaseError.includes(error)) return 'error.T2CBaseError'
  if (T2CTechError.includes(error)) return 'error.T2CTechError'
  return 'error.T2CBaseError'
}
