/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo } from 'react'
import PhoneInput from 'react-phone-input-2'
import './CSS/PhoneInput.css'
import { startsWith } from 'lodash'
import { Markup } from 'interweave'
import { TFunction } from 'i18next'

interface PhoneInputProps {
  value: string
  onChange: (e: any) => void
  onFocus?: (e: any) => void
  onBlur?: (e: any) => void
  t: TFunction<'translation', undefined, 'translation'>
  styles?: string
  error?: string | false | undefined
}

const PhoneInputComponent: React.FC<PhoneInputProps> = ({
  value,
  onChange,
  onFocus,
  onBlur,
  t,
  styles = '',
  error,
}) => {
  const [isValueValid, setIsValueValid] = React.useState<boolean>(false)
  return (
    <div className={`w-full py-10  ${styles} ${error && '!border-red-600'}`}>
      <PhoneInput
        country='md'
        inputClass={`w-full h-12 rounded-md border-[1px] border-gray-300 focus:border-transparent ${
          error && '!border-red-500'
        }`}
        isValid={(inputNumber, country, countries) =>
          countries.some((selectedCountry) => {
            setIsValueValid(
              // @ts-ignore
              startsWith(inputNumber, selectedCountry?.dialCode) ||
                // @ts-ignore
                startsWith(selectedCountry.dialCode, inputNumber),
            )
            return (
              // @ts-ignore
              startsWith(inputNumber, selectedCountry.dialCode) ||
              // @ts-ignore
              startsWith(selectedCountry.dialCode, inputNumber)
            )
          })
        }
        specialLabel=''
        placeholder='+373 (00) 000 000'
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        defaultMask='+(...) .. ... ...'
        autocompleteSearch
      />
      {!isValueValid && (
        <div className='text-red-500 text-xs mt-[10px]'>
          <Markup content={t('error.phoneError')} />
        </div>
      )}
      {error && (
        <div className='text-red-500 text-xs mt-[10px]'>
          <Markup content={t(`${error}`)} />
        </div>
      )}
    </div>
  )
}
export default memo(PhoneInputComponent)
