import React, { useContext } from 'react'

export const reCaptchaV2Context = React.createContext('')

export const GoogleReCaptchaV2Provider = reCaptchaV2Context.Provider

const useReCaptchaV2 = (): { reCaptchaV2SiteKey: string } => {
  const reCaptchaV2SiteKey = useContext(reCaptchaV2Context)
  return { reCaptchaV2SiteKey }
}

export default useReCaptchaV2
