import React from 'react'
import { useTranslation } from 'react-i18next'
import MaibLogo from '../assets/svg/maib.svg'
import PhoneIcon from '../assets/svg/phone-icon.svg'
import ArrowUp from '../assets/svg/arrow-up.svg'
import PDFViewer from './PDFViewer'
import rates from '../assets/pdf/tarifele_limite_p2p_ro.pdf'
import instructions from '../assets/pdf/instructiunea_p2p.pdf'
import ratesT2C from '../assets/pdf/tarife_T2C.pdf'

const FooterLink = ({
  link,
  text,
  styleText,
}: {
  link: string
  text: string
  styleText?: string
}) => {
  return (
    <a
      href={link}
      target='_blank'
      className={`underline hover:no-underline decoration-1 lowercase transition sm:text-start text-center duration-150 ease-out hover:ease-in-out text-white cursor-pointer ${styleText}`}
      rel='noreferrer'
    >
      {' '}
      {text}
    </a>
  )
}

FooterLink.defaultProps = {
  styleText: '',
}

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className='shadow bg-main font-nexaBold pt-16 pb-8 px-4 sm:px-6 lg:px-8'>
      <div className='container mx-auto flex sm:flex-row flex-col sm:mb-16 mb-8  sm:items-start  justify-between'>
        <div className='w-full sm:w-1/3 md:w-1/2 items-start flex flex-col sm:mb-0 mb-8'>
          <h1 className=' text-footerText font-bold mb-4'>{t('footer.contact')}</h1>
          <div className='flex flex-row items-center'>
            <img src={PhoneIcon} alt='Phone' className='mr-2' />
            <a
              href='tel:1313'
              className='text-white font-bold text-base hover:text-secondary cursor-pointer'
            >
              {t('footer.phone')}
            </a>
          </div>
          <div className='flex flex-row items-center'>
            <img src={PhoneIcon} alt='Phone' className='mr-2' />
            <a
              href='tel:+373 22 45 06 03'
              className='text-white font-bold text-base hover:text-secondary cursor-pointer'
            >
              +373 22 45 06 03
            </a>
          </div>
        </div>
        <div className='w-full sm:w-1/3 md:w-6/12 items-start flex flex-col sm:mb-0 mb-8'>
          <h1 className=' text-footerText font-bold mb-4'>{t('footer.mainInfo')}</h1>
          <div className='flex flex-col items-start'>
            <PDFViewer
              name={t('footer.instructions_for_use')}
              // pdf={`${client.defaults.baseURL}/docs/instructions`}
              pdf={instructions}
            />
            <PDFViewer name={t('footer.rates_and_limits')} pdf={rates} />
            <PDFViewer name={t('footer.rates_and_limits_t2c')} pdf={ratesT2C} />
          </div>
        </div>
        {/* <div className='w-full sm:w-1/3 md:w-3/12 items-start flex flex-col sm:mb-0 mb-8'> */}
        {/*    <h1 className=' text-footerText font-bold mb-4'>{t('footer.visaAlias')}</h1> */}
        {/*    <span */}
        {/*        className=' border-white border-[1px] rounded-3xl py-2 px-4  hover:no-underline lowercase transition duration-150 ease-out hover:ease-in-out text-white cursor-pointer'> {t('footer.unsubscribe_visa_alias')}</span> */}
        {/* </div> */}
      </div>
      <div className='container h-[1px] sm:flex hidden rounded bg-footerText mx-auto mb-4' />
      <div className='container mx-auto flex sm:flex-row flex-col-reverse items-start '>
        <div className='flex sm:flex-row flex-col sm:items-center justify-between sm:w-1/2 w-full'>
          <a href='/' className='flex items-center mb-4 sm:mb-0 ' aria-label='Home'>
            <img src={MaibLogo} alt='' />
          </a>
          <span className='font-nexaLight text-footerText text-center  text-sm'>
            {t('footer.copyRight').replace('2022', String(new Date().getFullYear()))}
          </span>
          <span className='font-nexaLight text-footerText text-sm' />
        </div>
        <div className=' sm:items-center flex flex-row sm:w-1/2 w-full justify-evenly sm:justify-end gap-2 sm:gap-6 mb-10 sm:mt-0 '>
          <FooterLink
            styleText='font-nexaLight'
            link='https://www.facebook.com/www.maib.md/'
            text={t('footer.facebook')}
          />
          <FooterLink
            styleText='font-nexaLight'
            link='https://www.instagram.com/maib_md/'
            text={t('footer.instagram')}
          />
          <FooterLink
            styleText='font-nexaLight'
            link='https://www.youtube.com/channel/UCCWcd1e2IhNZlFtFJ64wfdw'
            text={t('footer.youtube')}
          />
          <a href='#top' className='flex items-center justify-start '>
            <img src={ArrowUp} alt='Arrow Down' className='cursor-pointer' />
          </a>
        </div>
        <div className='container h-[1px] sm:hidden flex rounded bg-footerText mx-auto mb-4' />
      </div>
    </footer>
  )
}

export default Footer
