import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Markup } from 'interweave'
import { Button } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import Success from '../assets/svg/success.svg'
import MaibRedirect from './MaibRedirect'
import { t2cPdfReceipt } from '../utils/api'

interface SuccesFormProps {
  handleSuccess: () => void
  moneygramOperationId?: string
}

interface DownloadPdfButtonProps {
  base64: string
  fileName: string
}

const DownloadPdfButton: FC<DownloadPdfButtonProps> = ({ base64, fileName }) => {
  const pdfDataUrl = `data:application/pdf;base64,${base64}`
  return (
    <a
      href={pdfDataUrl}
      download={fileName}
      className='my-8 sm:w-1/4 w-full h-[64px] border-gray border-[1px] rounded-[48px] color-black flex items-center justify-center no-underline'
    >
      <div>Download PDF</div>
    </a>
  )
}
const SuccesForm: React.FC<SuccesFormProps> = ({ handleSuccess, moneygramOperationId }) => {
  const { t } = useTranslation()
  const { data, isSuccess } = useQuery(
    ['t2cPdfReceipt'],
    () => t2cPdfReceipt(moneygramOperationId || ''),
    {
      enabled: !!moneygramOperationId,
    },
  )
  return (
    <div className='container mx-auto flex flex-col px-auto flex-auto my-16'>
      <div className='d-flex md:mx-0 mx-4'>
        <MaibRedirect />
      </div>
      <div className='d-flex md:mx-0 mx-4 px-4 py-4 sm:px-11 sm:py-11 mb-24 rounded-2xl  bg-white border-[1px] border-gray-100 shadow'>
        <div className='border-[2px] sm:px-[48px] border-secondary px-8 py-8 sm:py-[80px] flex flex-col items-center justify-center'>
          <img src={Success} alt='' />
          <div className='text-center my-8'>
            <div className='text-main text-center font-nexaBold text-[18px] sm:text-[36px]'>
              <Markup content={t('p2p-page.success.title')} />
            </div>
          </div>
          {moneygramOperationId && data?.result && data?.ok && isSuccess && (
            <DownloadPdfButton
              base64={data.result.pdfReceipt}
              fileName={`${moneygramOperationId}.pdf`}
            />
          )}
          <Button variant='contained' className='mt-8 sm:w-1/4 w-full' onClick={handleSuccess}>
            <Markup content={t('p2p-page.success.button')} />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SuccesForm
