import React, { useEffect } from 'react'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { IntroForm, MainForm } from './components'
import { UtilitiesSteps } from '../../utils/enum'
import {
  getUtilitiesCategories,
  getUtilitiesProviders,
  postUtilitiesProvider,
  utilitiesInitSession,
} from '../../utils/api'
import { IUtilitiesProduct } from './components/types'
import ConfirmationForm from './components/ConfirmationForm'

const Utilities = () => {
  const navigate = useNavigate()
  const [step, setStep] = React.useState<UtilitiesSteps>(0)
  const [activeCategory, setActiveCategory] = React.useState<string>('')
  const [activeProduct, setActiveProduct] = React.useState<IUtilitiesProduct | null>(null)
  const [search, setSearch] = React.useState<string>('')
  const [session, setSession] = React.useState<any>(null)
  const [providerSession, setProviderSession] = React.useState<any>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [identifier, setIdentifier] = React.useState<string>('')

  const { isError: sessionError, isLoading: sessionLoading } = useQuery(
    ['UtilitiesSession'],
    () =>
      utilitiesInitSession().then((res) => {
        if (res.ok) {
          setSession(res.result.sessionId)
        } else {
          navigate('/error', { state: { location: window.location.pathname } })
        }
        return res
      }),
    {
      refetchOnMount: true,
    },
  )
  const { data: categoriesList, isError } = useQuery(
    ['UtilitiesCategories'],
    () =>
      getUtilitiesCategories().then((res) => {
        if (res.ok) {
          return res
        }
        navigate('/error', { state: { location: window.location.pathname } })
        return null
      }),
    {
      refetchOnMount: true,
    },
  )

  const {
    data: providersData,
    isError: ProvidersErrors,
    isLoading: providersLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    refetch,
  } = useInfiniteQuery(
    ['utilitiesProviders', activeCategory],
    ({ pageParam = 1 }) => getUtilitiesProviders(activeCategory, search, pageParam, 10),
    {
      getNextPageParam: (lastPage: any) =>
        lastPage.result.pageNumber < lastPage.totalPages ? lastPage.page + 1 : null,
      enabled: activeCategory !== '',
    },
  )

  const handleInitTransaction = async (identifierId: string, formik: any) => {
    setIsLoading(true)
    await postUtilitiesProvider(activeProduct?.id || '', session, identifierId)
      .then((res) => {
        setIsLoading(false)
        if (res.ok) {
          setIdentifier(identifierId)
          setProviderSession(res.result)
          setStep(UtilitiesSteps.TRANSACTION_COMPLETE)
        } else {
          formik.setFieldError('identifier', res.error.message)
        }
      })
      .catch((e) => {
        setIsLoading(false)
        formik.setFieldError('identifier', e.response.data.errors[0].errorMessage)
        // navigate('/error', {state: {location: window.location.pathname}})
      })
  }

  useEffect(() => {
    const delay = setTimeout(() => {
      refetch()
    }, 1000)

    return () => clearTimeout(delay)
  }, [search, refetch])
  const providers = providersData?.pages.flatMap((page) => page.result?.items) || []

  const handleLoadMore = () => {
    fetchNextPage()
  }

  if (isError || ProvidersErrors || sessionError) {
    navigate('/error', { state: { location: window.location.pathname } })
  }

  const activeColor =
    step === UtilitiesSteps.TRANSACTION_COMPLETE ? 'bg-white' : 'bg-mainBackground'
  const handleCategoryPress = (category: string) => {
    setActiveCategory(category)
    setActiveProduct(null)
  }

  const activeStep = () => {
    switch (step) {
      case UtilitiesSteps.TRANSACTION_INTRO:
        return (
          <IntroForm
            handleCategoryPress={setActiveCategory}
            setStep={setStep}
            categoriesList={categoriesList?.result || []}
          />
        )
      case UtilitiesSteps.TRANSACTION_INIT:
        return (
          <MainForm
            handleCategoryPress={handleCategoryPress}
            activeCategory={activeCategory}
            setStep={setStep}
            providers={providers}
            handleLoadMore={handleLoadMore}
            hasNextPage={hasNextPage}
            categoriesList={categoriesList?.result || []}
            activeProduct={activeProduct}
            setActiveProduct={setActiveProduct}
            setSearch={setSearch}
            search={search}
            providersLoading={providersLoading}
            handleInitTransaction={handleInitTransaction}
            isFetching={isFetching}
          />
        )
      case UtilitiesSteps.TRANSACTION_COMPLETE:
        return (
          <ConfirmationForm
            activeProduct={activeProduct}
            providerSession={providerSession}
            session={session}
            identifier={identifier || ''}
          />
        )
      default:
        return <IntroForm setStep={setStep} handleCategoryPress={setActiveCategory} />
    }
  }

  return (
    <div
      className={`min-h-full h-full flex-1 flex flex-col items-center w-full relative ${activeColor}`}
    >
      <div className='flex flex-col items-center w-full min-h-full h-full'>
        {(isLoading || sessionLoading || providersLoading || isFetching) && (
          <div className='absolute top-0 left-0 w-full h-full flex-1 bg-white bg-opacity-50 flex justify-center items-center z-[1000]'>
            <CircularProgress size={40} thickness={4} color='primary' />
          </div>
        )}
        {activeStep()}
      </div>
      {/* {step === UtilitiesSteps.TRANSACTION_INIT && <InfoBlock />} */}
    </div>
  )
}

export default Utilities
