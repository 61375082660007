import React, { FC, useState } from 'react'
import { Collapse, Grow, Menu, MenuItem } from '@mui/material'
import Viber from '../assets/svg/viber.svg'
import Telegram from '../assets/svg/telegram.svg'

const ViberSupport: FC = () => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div className='fixed top-3/4 right-6'>
      <div
        className=' w-[34px] h-[34px] right-[20px] flex flex-col items-center justify-center bg-secondary rounded-full cursor-pointer sm:hover:bg-main transition duration-300 ease-in-out'
        onClick={handleClick}
        role='button'
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleClick(event)
          }
        }}
        aria-label='Viber'
      >
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.06 2a10.02 10.02 0 0 0-8.92 14.63l.2.39c.16.3.2.67.1 1-.3.76-.53 1.53-.72 2.32 0 .4.11.63.54.62.76-.17 1.51-.38 2.24-.65.32-.08.65-.06.96.06.27.13.84.48.86.48A10.03 10.03 0 0 0 21.37 8.5 10.02 10.02 0 0 0 12.06 2Z'
            fill='#fff'
          />
          <path
            d='M7.33 13.33a1.33 1.33 0 1 0 0-2.66 1.33 1.33 0 0 0 0 2.66Zm4.77 0a1.33 1.33 0 1 0 0-2.66 1.33 1.33 0 0 0 0 2.66Zm4.77 0a1.33 1.33 0 1 0 0-2.66 1.33 1.33 0 0 0 0 2.66Z'
            fill='#40C1AC'
          />
        </svg>
      </div>

      <Menu
        className={`${
          open ? 'block' : 'hidden'
        } transition-all duration-1000 ease-in-out bg-transparent`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
        hideBackdrop={false}
        disableScrollLock
        transitionDuration={1000}
        elevation={1}
        anchorOrigin={{ vertical: 5, horizontal: +30 }}
        transformOrigin={{ vertical: 110, horizontal: 0 }}
        sx={{
          backgroundColor: 'transparent',

          '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderColor: 'transparent',
          },
          '& .MuiMenu-list': {
            '& .MuiMenuItem-root': {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        }}
      >
        <Collapse in={open} timeout={1000}>
          <MenuItem>
            <a href='tg://resolve?domain=maibsupportbot&start=operator'>
              <img
                src={Telegram}
                alt='telegram'
                style={{
                  width: '34px',
                  height: '34px',
                  borderRadius: '50%',
                  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                  backgroundColor: '#fff',
                }}
              />
            </a>
          </MenuItem>
          <MenuItem>
            <a href='viber://pa?chatURI=maibsupportbot&context=operator'>
              <img
                src={Viber}
                alt='viber'
                style={{
                  width: '34px',
                  height: '34px',
                  borderRadius: '50%',
                  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                  backgroundColor: '#fff',
                }}
              />
            </a>
          </MenuItem>
        </Collapse>
      </Menu>
    </div>
  )
}

export default ViberSupport
