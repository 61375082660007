import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Markup } from 'interweave'
import { Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import Error from '../../assets/svg/error.svg'
import { P2PErrorsValidation } from '../../utils/errors'

const ErrorPage: FC = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const errorCode = urlParams.get('errorCode')
  const error = errorCode && P2PErrorsValidation(errorCode)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const location = state?.location || '/#top'
  return (
    <div className=' flex flex-col flex-auto my-16 w-full px-4 items-center justify-center '>
      <div className='flex md:mx-0 px-4 py-4 sm:px-11 sm:py-11 mb-24 rounded-2xl w-full container  bg-white border-[1px] border-fireRed-100 shadow items-center justify-center'>
        <div className='border-[2px] sm:px-[24px] border-fireRed px-8 py-8 sm:py-[80px] w-full flex flex-col items-center justify-center'>
          <img src={Error} alt='' />
          <div className='text-center my-8'>
            <div className='text-center font-nexaBold text-fireRed text-[18px] sm:text-[36px]'>
              <Markup content={t('p2p-page.error.title')} />
            </div>
            {errorCode && error && (
              <div className='text-main text-center font-nexaBold text-[14px] sm:text-[14px] mt-4'>
                <Markup content={t(`${error}`)} />
              </div>
            )}
          </div>
          <Button
            variant='contained'
            className='mt-8 sm:w-2/4 w-full bg-main hover:bg-secondary'
            onClick={() => {
              navigate(`${location}`, { replace: true })
            }}
          >
            <Markup content={t('p2p-page.error.button')} />
          </Button>
          <a
            href='tel:+1313'
            className='text-center mt-8 sm:w-2/4 w-full rounded-[30px] hover:border-secondary border-gray-500 border-[1px]'
          >
            <div className='text-main text-center font-nexaBold text-[16px] py-4 sm:py-[20px]'>
              {t('p2p-page.error.button2')}
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
