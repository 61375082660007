/* eslint-disable no-shadow */
import React, { type FC, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Button, CircularProgress } from '@mui/material'
import valid from 'card-validator'
import { useNavigate } from 'react-router-dom'
import { postRecalculate, postUtilitiesPay } from '../../../../utils/api'
import { CreditCardForm, InvoicesAmountForm } from './components'
import { PaymentSteps } from '../../../../utils/enum'
import ArrowLeft from '../../../../assets/svg/arrow-left.svg'

interface IConfirmationForm {
  activeProduct: any
  providerSession: any
  session: any
  identifier: string
}

interface IDetailsLine {
  title: string
  value: string
}

const DetailsLine: FC<IDetailsLine> = ({ title, value }) => {
  return (
    <div className='flex flex-row justify-between w-full p-2 rounded-[12px]'>
      <div className='font-nexaLight text-[14px] text-mainText truncate max-w:1/2'>{title}</div>
      <div className='font-nexaBold text-[14px] text-mainText ml-2'>{value}</div>
    </div>
  )
}

const ConfirmationForm: FC<IConfirmationForm> = ({
  activeProduct,
  providerSession,
  session,
  identifier,
}) => {
  const { t } = useTranslation()
  const [step, setStep] = useState<PaymentSteps>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const imageTitleSplit = activeProduct?.image?.title.split('.') || []
  const imageType = imageTitleSplit[imageTitleSplit.length - 1] || 'png'
  const [validatedValues, setValidatedValues] = useState<any>(null)
  const navigate = useNavigate()

  const initialValues = () => {
    const fields = providerSession.payments || []
    return fields.reduce((acc: any, field: any) => {
      const counterNameArr = field?.counterName.split('|')
      const counterOldArr = field?.counterOld.split('|')
      const counters = field.totalCounters
        ? counterNameArr.reduce((acc: any, name: string, index: number) => {
            acc[index] = {
              name,
              oldValue: counterOldArr[index] || '0',
              value: 0,
              visible: true,
            }
            return acc
          }, [])
        : []
      acc[field.id] = {
        id: field.id,
        value: field.amount || '',
        details: field.details || null,
        checked: true,
        counters,
      }
      return acc
    }, {})
  }

  const getMinimumValue = (payment: any) => {
    if (payment.amountMask === 1) return payment.amount
    if (payment.amountMin === 0) return 1
    return payment.amountMin
  }

  const getMaximumValue = (payment: any) => {
    if (payment.amountMask === 2) return payment.amount
    if (payment.amountMax === 0) return 20000
    return payment.amountMax
  }

  const paymentValidations = providerSession.payments.reduce((acc: any, payment: any) => {
    const minimumValue = getMinimumValue(payment)
    const maximumValue = getMaximumValue(payment)

    acc[payment.id] = Yup.object().shape({
      value: Yup.number()
        .required()
        .min(minimumValue, 'p2p-page.error.error')
        .max(maximumValue, 'p2p-page.error.error'),
      counters: Yup.array().of(
        Yup.object().shape({
          oldValue: Yup.number().required(),
          visible: Yup.boolean(),
          value: Yup.number()
            .required()
            .test('min-value-test', 'p2p-page.error.error', function (value) {
              // eslint-disable-next-line react/no-this-in-sfc
              const { oldValue, visible } = this.parent
              // if (!visible) {
              if (oldValue || (value && Number(oldValue) > 0)) {
                return Number(value) >= Number(oldValue)
              }
              return Number(value) >= 0
              // }
              // return true;
            }),
        }),
      ),
    })

    return acc
  }, {})

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object().shape({
      ...paymentValidations,
    }),
    onSubmit: async (values) => {
      const finalValues = Object.values(values).reduce((acc: any[], value: any) => {
        if (value.checked) {
          const valuesString = value?.counters
            ? value?.counters?.map((counter: any) => counter.value).join('|')
            : ''

          // eslint-disable-next-line no-param-reassign
          acc = [
            ...acc,
            {
              ...value,
              amount: Number(value.value),
              counter: valuesString,
            },
          ]
        }
        return acc
      }, [])

      if (finalValues.length === 0) {
        formik.setFieldError('errorPayments', 'p2p-page.error.error')
        return
      }
      const data = await postRecalculate(session, finalValues)
      setValidatedValues(data)
      setStep(PaymentSteps.PAYMENT_INIT)
    },
  })
  const cardFormik = useFormik({
    initialValues: {
      cardNumber: '',
      expirationDate: '',
      cvv: '',
      firstName: '',
      lastName: '',
    },
    validationSchema: Yup.object().shape({
      cardNumber: Yup.string()
        .matches(/^[0-9\s]*$/, 'p2p-page.error.error')
        .test(
          'test-number',
          'error.errorCard',
          (value) => valid.number(value && value.split(' ').join('')).isValid,
        )
        .required('p2p-page.error.required'),
      expirationDate: Yup.string()
        .required('p2p-page.error.required')
        .matches(/^[0-9]{2}\/[0-9]{2}$/, 'p2p-page.error.error'),
      cvv: Yup.string()
        .required('p2p-page.error.required')
        .matches(/^[0-9]{3,4}$/, 'p2p-page.error.error'),
      firstName: Yup.string().required('p2p-page.error.required'),
      lastName: Yup.string().required('p2p-page.error.required'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true)
      const data = await postUtilitiesPay(session, values)
      const formValues = {
        card: values.cardNumber,
        cvv: values.cvv,
        cardMonth: values.expirationDate.split('/')[0],
        cardYear: values.expirationDate.split('/')[1],
        operationId: session,
      }
      if (data.ok) {
        setIsLoading(false)
        const form = document.createElement('form')
        form.method = 'post'
        form.action = data.result.redirectUrl
        Object.entries(formValues).forEach(([key, value]) => {
          const input = document.createElement('input')
          input.type = 'hidden'
          input.name = key
          input.value = value
          form.appendChild(input)
        })
        document.body.appendChild(form)
        form.submit()
      } else {
        setIsLoading(false)
        navigate('/error', { state: { location: window.location.pathname } })
      }
    },
  })
  const headerText = () => (
    <div className='w-full flex flex-col items-center justify-center mt-12'>
      <img
        src={`data:image/${imageType === 'svg' ? 'svg+xml' : imageType};base64,${
          activeProduct?.image?.base64
        }`}
        alt={activeProduct?.title}
        className='h-[48px]'
      />
      <div className='mt-3 font-nexaBold text-[32px] pb-6'> {activeProduct?.title} </div>
      {/* <HeaderStepper step={step + 1} /> */}
    </div>
  )

  const activeFormStep = () => {
    switch (step) {
      case PaymentSteps.PAYMENT_INTRO:
        return (
          <div className='w-full flex flex-col items-center justify-center bg-mainBackground px-4 mx-4 sm:py-12 py-6  h-full rounded-[12px]  '>
            <InvoicesAmountForm
              fields={providerSession?.payments || []}
              formik={formik}
              hasAutoCounter={providerSession.hasAutoCounter || false}
            />
            <div className='flex flex-row justify-between w-full mt-2 mb-4 sm:w-6/12 max-w-[342px] w-full bg-[#EDEFF5] p-4 rounded-[12px]'>
              <div className='font-nexaLight text-[14px] text-mainText truncate max-w:1/2'>
                {t('utilities.paymentForm.identifier')}
              </div>
              <div className='font-nexaBold text-[14px] text-mainText ml-2'>{identifier}</div>
            </div>
            <Button
              variant='contained'
              className='sm:!w-6/12 !max-w-[342px] !w-full !text-white'
              onClick={() => formik.submitForm()}
              disabled={formik.isSubmitting || !formik.isValid || isLoading}
            >
              {t('utilities.paymentForm.continue')}
            </Button>
            <div className='font-nexaLight text-[14px] text-fireRed mt-2'>
              {typeof formik.errors?.errorPayments === 'string'
                ? t(formik.errors.errorPayments)
                : ''}
            </div>
          </div>
        )
      case PaymentSteps.PAYMENT_INIT:
        return (
          <div className='w-full flex flex-col items-center justify-center bg-mainBackground px-4 mx-4 sm:py-12 py-6  h-full rounded-[12px] relative'>
            <div
              role='button'
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setStep(PaymentSteps.PAYMENT_INTRO)
                }
              }}
              className='flex absolute top-4 left-4 cursor-pointer'
              onClick={() => setStep(PaymentSteps.PAYMENT_INTRO)}
            >
              <img src={ArrowLeft} alt='arrow-left' className='h-[24px]' />
            </div>

            <CreditCardForm formik={cardFormik} />
            <div className='flex flex-col justify-between w-full mt-2 mb-4  max-w-[342px] sm:w-6/12 bg-white p-4 rounded-[12px]'>
              <DetailsLine title={t('utilities.paymentForm.identifier')} value={identifier} />
              <div className='w-full h-[1px] bg-[#EDEFF5] my-2' />
              <DetailsLine
                title={t('utilities.paymentForm.amount')}
                value={`${validatedValues?.result?.totalAmount} MDL`}
              />
              <DetailsLine
                title={t('utilities.paymentForm.fee')}
                value={`${validatedValues?.result?.totalFee} MDL`}
              />
              <DetailsLine
                title={t('utilities.paymentForm.total')}
                value={`${validatedValues?.result?.total} MDL`}
              />
            </div>
            <Button
              variant='contained'
              tabIndex={0}
              className='sm:!w-6/12 !max-w-[342px] !w-full !text-white'
              onClick={() => cardFormik.submitForm()}
              disabled={!cardFormik.isValid}
            >
              {t('utilities.paymentForm.pay')}
            </Button>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className='w-full h-full flex flex-col relative items-center justify-center'>
      {isLoading && (
        <div className='flex justify-center items-center absolute top-0 left-0 right-0 bottom-0 z-50 bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm'>
          <CircularProgress color='success' />
        </div>
      )}
      {headerText()}
      <div className='container flex items-center justify-center py-8'>{activeFormStep()}</div>
    </div>
  )
}
export default ConfirmationForm
